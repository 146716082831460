import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { getCompanyColors } from "../CompanyUtils/CompanyColor";
import formatNumber from "../../utils/FormatNumber";

const latest_created_at = localStorage.getItem("latest_records");

const ComparisonTempLineChart = ({
  data,
  isDetailedChart,
  isCombinedChart = false,
  isStaffChart = false,
  isNotPercentage = false,
  isSecondaryChart = false,
  secondaryData = null,
  range = null,
  // isOverlapChart = false,
  isXSScreen = false,
  // allowNegativeY = false,
  // showZeroLine = false,
  isMinimal = false,
  // singleTooltip = false,
  hasPredictionLine = false,
  predictionLineDate = null,
  isMinNonZero = false,
  isCurrency = false,
  noPrefix = false,
  hasStaffMarker = false,
  dataRange = null,
}) => {
  //SANDBOX
  //SANDBOX
  let newVar;
  let secondVar;
  console.log(data, "initialfinaldata");

  // const [activeId, setActiveId] = useState(null);
  const [hiddenIds, setHiddenIds] = useState([]);

  function formatSerieId(serieId) {
    return serieId
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const format = !isNotPercentage
    ? (v) => `${v}%`
    : isCurrency
    ? (v) => `${v} NOK`
    : (v) => `${v} units`;

  if (!isDetailedChart) {
    newVar = [];
  }
  for (const property in data) {
    newVar.push(data[property]);
  }
  const lineData =
    newVar && Object.entries(newVar).length !== 0 ? [newVar] : [];
  if (isCombinedChart) {
    //combined chart
    newVar = newVar[0];
  }

  // Calculate tick values for small screens

  if (secondaryData) {
    secondVar = [];
    for (const property in secondaryData) {
      secondVar.push(secondaryData[property]);
    }
  }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      return {
        ...series,
        data: series.data.map((point) => {
          return {
            ...point,
            y: isNaN(point.y) ? 0 : point.y,
          };
        }),
      };
    });
  }

  // Filter data based on active legend item

  let minYValue;
  let maxYValue;
  if (newVar && newVar.length > 0) {
    // Extract all y-values from all data series and filter out NaN values
    const allYValues = newVar.flatMap((series) =>
      series.data.map((point) => point.y).filter((y) => !isNaN(y))
    );

    // Check if there are any valid values left after filtering
    if (allYValues.length > 0) {
      // Find the minimum and maximum y-values from the filtered array
      minYValue = Math.min(...allYValues);
      maxYValue = Math.max(...allYValues);
      if (minYValue === maxYValue) {
        minYValue = 0;
      }
    } else {
      // Default values if no valid data is available
      minYValue = 0;
      maxYValue = 0;
    }
  } else {
    // Default values if no data is available
    minYValue = 0;
    maxYValue = 0;
  }

  minYValue = minYValue.toFixed(0);
  maxYValue = maxYValue.toFixed(0);

  if (maxYValue < 10) {
  } else if (isNotPercentage) {
    maxYValue =
      maxYValue < 1000
        ? Math.round(maxYValue / 100) * 100
        : maxYValue < 9000
        ? Math.round(maxYValue / 1000) * 1000
        : Math.round(maxYValue / 10000) * 10000;
  } else {
    // For percentages or other units
    maxYValue = Math.round(maxYValue / 10) * 10; // Adjust rounding logic as needed
  }
  if (newVar && newVar.length > 0) {
    newVar = newVar.map((series) => {
      // Assuming `actualDate` is a representative date for the series to extract the year
      const representativeDate = new Date(series.data[0]?.actualDate);
      const year = representativeDate.getFullYear();
      return {
        ...series,
        id: `${year}`, // Set the series ID to the year
      };
    });
  }

  // console.log("Calculated maxYValue:", minYValue);
  let staffMarkerDate;
  if (hasStaffMarker && latest_created_at) {
    staffMarkerDate = new Date(latest_created_at);
    staffMarkerDate.setDate(staffMarkerDate.getDate() + 14);
    staffMarkerDate.setHours(0, 0, 0, 0);
  }
  let predictionLineMarkerDate;
  if (hasPredictionLine && latest_created_at) {
    predictionLineMarkerDate = new Date(latest_created_at);
    // Set the time to midnight to align with the graph points
    predictionLineMarkerDate.setHours(0, 0, 0, 0);
  }
  const pointTooltip = ({ point }) => {
    const date = new Date(point.data.actualDate);
    let formattedDate;

    if (range === "month") {
      formattedDate = date.toLocaleDateString(undefined, {
        month: "long",
        year: "numeric",
      });
    } else if (range === "week") {
      const sunday = new Date(date);
      sunday.setDate(sunday.getDate() - sunday.getDay());

      const startOfYear = new Date(sunday.getFullYear(), 0, 1);
      const dayDifference = (sunday - startOfYear) / (24 * 60 * 60 * 1000);
      const weekNumber =
        1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);

      formattedDate = `${date.toLocaleDateString(undefined, {
        month: "long",
      })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
    } else {
      formattedDate = date.toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    // Ensure both dates are compared in the same format

    return (
      <div
        style={{
          background: "white",
          padding: "10px",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          borderRadius: "5px",
          // position: 'absolute',
          // left: `${point.x - 50}px`, // Adjust the left position based on the point’s x-coordinate
          // top: `${point.y + 20}px`, // Adjust the top position to place the tooltip below the point
        }}
      >
        <strong>{formattedDate}</strong>
        <br />
        <strong style={{ color: point.serieColor }}>
          {formatSerieId(point.serieId)}:{" "}
          {isNotPercentage
            ? formatNumber(Math.round(point.data.y))
            : `${point.data.y} units`}
          <br />
          {point.data.comment && (
            <strong style={{ color: "black" }}>
              Comment: {point.data.comment}
              <br />
            </strong>
          )}
        </strong>
        {/* <br/> */}
      </div>
    );
  };

  const axisProps = !isSecondaryChart
    ? {
        axisLeft: {
          format,
          tickValues: [minYValue, maxYValue],
          legendOffset: 12,
        },
        colors: getCompanyColors(),
        // colors: [

        //   // "#E191B1", // Light Pink
        //   // "#2FEBAA", // Mint Green
        //   "#543c8c",
        //   "#e394b4",

        //   "#949494",

        //   "#70335D", // Dark Plum
        //   // "#91FAE0", // Turquoises
        //   "#17CB1B",

        //   "#2A5F8B", // Steel Blue

        //   // "#E63946",
        //   // "#40E0D0",
        //   // "#4169E1",
        //   "#bcbd22", //green
        //   // "#2ca02c",
        //   // "#d62728",
        //   // "#9467bd",
        //   // "#8c564b",
        //   "#e377c2",
        //   "#7f7f7f",
        //   "#17becf",
        // ],
        tooltip: pointTooltip,
      }
    : {
        colors: "#4169E1",
        axisRight: {
          format,
          legendOffset: 12,
        },
        sliceTooltip: ({ slice }) => {
          const currentDate = new Date(slice.points[0].data.x);
          let formattedDate;

          if (range === "month") {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            });
          } else if (range === "week") {
            // Adjust to Sunday of the current week
            const sunday = new Date(currentDate);
            sunday.setDate(sunday.getDate() - sunday.getDay());

            // January 4th is always in week 1 according to ISO standard
            const startOfYear = new Date(sunday.getFullYear(), 0, 1);

            // Calculate the number of days between the date and the start of the year
            const dayDifference =
              (sunday - startOfYear) / (24 * 60 * 60 * 1000);

            // Calculate ISO week number
            const weekNumber =
              1 + Math.floor((dayDifference + startOfYear.getDay() + 5) / 7);
            formattedDate = `${currentDate.toLocaleDateString(undefined, {
              month: "long",
            })} (Week ${weekNumber}) ${sunday.getFullYear()}`;
          } else {
            formattedDate = currentDate.toLocaleDateString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }

          // Find the object with the matching x value in secondarySalesData
          const matchedData = secondVar[0].data.find(
            (d) =>
              d.x.toISOString().split("T")[0] ===
              currentDate.toISOString().split("T")[0]
          );

          // If matchedData is found, extract the y value, else set it to null
          const secondaryYValue = matchedData ? matchedData.y : null;

          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <strong>{formattedDate}</strong>
              {slice.points.map((point, index) => (
                <div
                  key={index}
                  style={{
                    color: "#4169E1",

                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}:</strong>{" "}
                  {parseInt(point.data.yFormatted)}
                  {secondaryYValue && (
                    <div
                      style={{
                        color: "#E63946", // or any color you prefer for secondary data
                        padding: "3px 0",
                      }}
                    >
                      <strong>Staff Cost Percentage:</strong> {secondaryYValue}%
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        },
      };

  let filteredData = newVar?.filter((serie) => !hiddenIds.includes(serie.id));

  const axisBottom =
    dataRange === "thisweek"
      ? {
          format: (value) => {
            if (isXSScreen) {
              return value.slice(0, 3); // Shorten weekday names to first three letters (Mon, Tue, etc.)
            }
            return value; // Use the full weekday names otherwise
          },
          legendOffset: -12,
          tickValues: filteredData[1]?.data.map((item) => item.x), // Use x values from the first array for "thisweek"
        }
      : null;

  return !lineData || lineData.length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <h2> No data</h2>
    </div>
  ) : (
    <ResponsiveLine
      animate
      enableGridX={isMinimal ? false : !isSecondaryChart}
      enableGridY={isMinimal ? true : !isSecondaryChart}
      gridYValues={[0, minYValue, maxYValue]}
      xScale={
        dataRange === "thisweek" || dataRange === "thismonth"
          ? {
              type: "point", // Continue using 'point' scale for "thisweek"
            }
          : {
              format: "%Y-%m-%d",
              precision: "day",
              type: "time",
              useUTC: false,
            }
      }
      axisBottom={axisBottom}
      {...axisProps}
      axisLeft={
        !isSecondaryChart
          ? {
              tickValues: [0, minYValue, isStaffChart ? 70 : maxYValue].filter(
                (v, i, a) => a.indexOf(v) === i
              ), // Ensuring no duplicates
              format: noPrefix ? undefined : (v) => format(v),
              legendOffset: -40,
            }
          : null
      }
      curve="monotoneX"
      data={filteredData}
      // enablePointLabel
      // height={400}
      margin={
        isMinimal
          ? {
              bottom: 80,
              left: noPrefix ? 80 : maxYValue > 100 ? 80 : 80,
              right: 50,
              top: 60,
            }
          : isStaffChart
          ? {
              bottom: 60,
              left: 80,
              right: 90,
              top: 20,
            }
          : {
              bottom: 60,
              left: 80,
              right: 40,
              top: 30,
            }
      }
      pointBorderColor={{
        from: "color",
        modifiers: [["darker", 0.3]],
      }}
      lineWidth={1}
      markers={[
        hasPredictionLine
          ? {
              axis: "x", // Vertical line
              // legend: "prediction marker",
              value: predictionLineMarkerDate,
              lineStyle: {
                stroke: "#505050", // Line color
                strokeWidth: 2, // Line width
              },

              legendOrientation: "vertical",
              legendPosition: "top-right",
            }
          : [],
      ]}
      pointBorderWidth={1}
      pointSize={4}
      enableArea={true}
      areaBaselineValue={isMinNonZero ? 0 : 0}
      areaOpacity={0.15}
      // enableSlices={singleTooltip ? undefined : "x"}
      labelFormat={format}
      useMesh
      //   xFormat="time:%Y-%m-%d"

      yScale={{
        type: "linear",
        // min: allowNegativeY ? "auto" : 0,
        min: minYValue < 0 ? minYValue : 0, // Always start from 0
        max: isStaffChart ? 70 : "auto", // Set the calculated maxY as the max property
      }}
      legends={
        isMinimal
          ? [
              {
                anchor: "bottom",
                direction: "row",
                translateY: 60,
                itemsSpacing: 2,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                onClick: (datum) => {
                  setHiddenIds((currentHiddenIds) => {
                    const isCurrentlyHidden = currentHiddenIds.includes(
                      datum.id
                    );
                    const newHiddenIds = isCurrentlyHidden
                      ? currentHiddenIds.filter((id) => id !== datum.id)
                      : [...currentHiddenIds, datum.id];

                    // Ensure we don't hide all lines: if newHiddenIds length is equal to the number of lines, reset it
                    if (newHiddenIds.length >= newVar.length) {
                      return [];
                    }
                    return newHiddenIds;
                  });
                },
              },
            ]
          : [
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: isSecondaryChart ? 150 : 2,
                translateY: 145,
                itemsSpacing: 10,
                itemDirection: "left-to-right",
                itemWidth: 109,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",

                symbolBorderColor: "rgba(0, 0, 0, .5)",

                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
    />
  );
};

export default ComparisonTempLineChart;
