import { React, useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./routes/UnauthenticatedRoutes";
import SuperAuthenticatedRoutes from "./routes/SuperAuthenticatedRoutes";
import BasicAuthenticatedRoutes from "./routes/BasicAuthenticatedRoutes";
import YipsAuthenticatedRoutes from "./routes/YipsAuthenticatedRoutes";

import PublicNavbar from "./components/PublicNavbar";
import Loading from "./components/Loading/Loading";
import { Box } from "@mui/material";
import NewEmployeeNavBar from "./components/NavBar/NewEmployeeNavBar";
import NewAdminNavBar from "./components/NavBar/NewAdminNavBar";
import BasicLevelNavBar from "./components/NavBar/BasicLevelNavBar";
import YipsNavBar from "./components/NavBar/YipsNavBar";

import Prefix from "./utils/PrefixLink";

const role = JSON.parse(localStorage.getItem("user"))?.role;
const subscriptionLevel = JSON.parse(
  localStorage.getItem("user")
)?.company_subscription_level;
const token = localStorage.getItem("token");
const prefix = Prefix();

const LoadingStatus = () => {
  return (
    <div className="App">
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          font: "3rem",
        }}
      >
        <Loading />
      </main>
    </div>
  );
};

const App = () => {
  const comp = localStorage.getItem("company");
  const [dataRange, setDataRange] = useState("today");
  const [selectedPage, setSelectedPage] = useState(
    role === "admin" ? "company" : "multiple"
  );
  const [company, setCompany] = useState(comp);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [hasPredictionData, setHasPredictionData] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthentication = useCallback(async () => {
    try {
      let url = `${prefix}/api/users/verify_token/`;
      const token = localStorage.getItem("token");
      if (token) {
        const company = localStorage.getItem("company");
        if (company) {
          url += `?company=${company}`;
        }
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        };

        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (data.success) {
          setIsManager(true);
          localStorage.setItem("latest_records", data["latest_created_at"]);
          const now = new Date(data["latest_created_at"]);

          let startingDate = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
          );
          let endingDate = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
          );

          setStartDate(startingDate.toISOString().split("T")[0]);
          setEndDate(endingDate.toISOString().split("T")[0]);

          const user = JSON.parse(localStorage.getItem("user"));
          if (user.role === "admin") {
            setIsSuperUser(true);
            setIsManager(false);
            setIsAuthenticated(true);
          } else if (user.role === "manager") {
            setIsSuperUser(false);
            setIsManager(true);
            setIsAuthenticated(true);
          } else {
            setIsSuperUser(false);
            setIsManager(false);
            setIsAuthenticated(true);
          }
          setIsLoading(false);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("latest_records");
          setIsLoading(false);
          window.location.href = "/";
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  }, []);

  const fetchRestaurants = useCallback(async () => {
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();
      setRestaurants(restro_data);
      if (restro_data && restro_data.length > 0) {
        setSelectedRestaurant(restro_data[0].name);
      }
    }
  }, [company]);

  useEffect(() => {
    checkAuthentication();
    const interval = setInterval(() => {
      checkAuthentication();
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [checkAuthentication]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  return (
    <Router>
      {isLoading ? (
        <LoadingStatus />
      ) : company === "Yips" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <YipsNavBar />
            <Box sx={{ width: "100%" }}>
              <YipsAuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : isSuperUser && subscriptionLevel === "premium" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <NewAdminNavBar />
            <Box sx={{ width: "100%" }}>
              <SuperAuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : isManager && subscriptionLevel === "premium" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <NewEmployeeNavBar />
            <Box sx={{ width: "100%" }}>
              <AuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : isAuthenticated && subscriptionLevel === "basic" ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: { lg: "flex-start", xs: "center" },
              width: "100%",
            }}
          >
            <BasicLevelNavBar />
            <Box sx={{ width: "100%" }}>
              <BasicAuthenticatedRoutes
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dataRange={dataRange}
                setDataRange={setDataRange}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                hasPredictionData={hasPredictionData}
                setHasPredictionData={setHasPredictionData}
                company={company}
                setCompany={setCompany}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <PublicNavbar />
            <UnAuthenticatedRoutes />
          </>
        </Box>
      )}
    </Router>
  );
};

export default App;
