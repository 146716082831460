import React, { useState, useRef, useEffect, useCallback } from "react";

import {
  //   Grid,
  //    Container,
  // Card,
  Typography,
  // TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Tooltip,
  Zoom,
  useMediaQuery,
  // Collapse,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  // Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PerBarChart from "../components/BarCharts/PerBarChart";
import getRandomColor from "../utils/RandomColor";
import PieChart from "../components/PieChart";
import ProdBarChart from "../components/BarCharts/ProdBarChart";
import SnackbarAlert from "../utils/SnackbarAlert";
import CloseIcon from "@mui/icons-material/Close";
import downloadDashboardTwo from "../utils/ExcelDownloads/DownloadDashboardTwo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
// import MoneyIcon from "@mui/icons-material/Money";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import ChartLoading from "../components/Loading/ChartLoading";
import LineChart from "../components/LineCharts/LineChart";
// import CategoryIcon from "@mui/icons-material/Category";
// import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LastUpdate from "../utils/LastUpdate";
import Prefix from "../utils/PrefixLink";
import KPILoad from "../components/Loading/KPILoad";
// import formatDateToDesiredFormat from "../utils/FormatDesiredDate";
import FormatDesiredDate from "../utils/FormatDesiredDate";
import SettingsDialog from "../components/SettingsDialog";
import TooltipCreate from "../components/Tooltips/Tooltip";
import TitleStyle from "../components/Style/Title";
import HelpModeButton from "../components/HelpModeButton";

// const comp = localStorage.getItem("company");
const token = localStorage.getItem("token");
const prefix = Prefix();
// const default_restaurant = JSON.parse(
//   localStorage.getItem("user")
// )?.restaurant_name;
const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const latest_created_at = localStorage.getItem("latest_records");

const NewSuperDashboardTwo = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  hasPredictionData,
  setHasPredictionData,
  range,
  company,
  setCompany,
}) => {
  const now = latest_created_at ? new Date(latest_created_at) : new Date();
  // const [company, setCompany] = useState(comp);
  // const [restaurants, setRestaurants] = useState([])
  // const [restaurantNames, setRestaurantNames] = useState(
  //   default_restaurant ? [default_restaurant] : []
  // );
  const [productMixPercentageData, setProductMixPercentageData] = useState([]);

  const [subPercentageProducts, setSubPercentageProducts] = useState([]);
  const [subPercentageProduct, setSubPercentageProduct] = useState(null);

  const [subGrossMarginProducts, setSubGrossMarginProducts] = useState([]);
  const [subGrossMarginProduct, setSubGrossMarginProduct] = useState(null);

  // const [dataRange, setDataRange] = useState("today");
  const [openDialog, setOpenDialog] = useState(false);

  const [averageBasketCost, setAverageBasketCost] = useState([]);
  const [grossProfitMarginGroupData, setGrossProfitMarginGroupData] = useState(
    []
  );
  const [percentageArticleGroupData, setPercentageArticleGroupData] = useState(
    []
  );

  const [deliveryCost, setDeliveryCost] = useState([]);
  const [subProducts, setSubProducts] = useState([]);

  const [selectedSubProduct, setSelectedSubProduct] = useState(null);

  const [productMixData, setProductMixData] = useState([]);
  const [articleGroupData, setArticleGroupData] = useState([]);
  const [subGrossProfitMarginData, setSubGrossProfitMarginData] = useState([]);
  const [averageBasketCostData, setAverageBasketCostData] = useState([]);
  const [grossProfitKPI, setGrossProfitKPI] = useState([]);
  const [highestSellerByValue, setHighestSellerByValue] = useState({
    product: "N/A",
    value: "N/A",
  });

  const [highestSellerByQuantity, setHighestSellerByQuantity] = useState({
    product: "N/A",
    value: "N/A",
  });

  const [grossProfitLoad, setGrossProfitLoad] = useState(false);
  const [prodBarLoad, setProdBarLoad] = useState(false);
  const [basketLoad, setBasketLoad] = useState(false);
  const [highestLoad, setHighestLoad] = useState(false);

  const [grossProfitMarginLoad, setGrossProfitMarginLoad] = useState(false);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState(null);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isPieVisible, setIsPieVisible] = useState(false);
  const [isSubCategoryVisible, setIsSubCategoryVisible] = useState(false);
  const [isSubGrossMarginVisible, setIsSubGrossMarginVisible] = useState(false);
  const [takeAwayData, setTakeAwayData] = useState(null);
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = 9;
  const [isCurrency, setIsCurrency] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));
  const email = user.email;
  const primaryRestaurant = user?.primary_restaurant;

  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const abortControllerRefForProductMix = useRef(new AbortController());
  const abortControllerRefForAverageBasket = useRef(new AbortController());
  const abortControllerRefForGrossProfitMargin = useRef(new AbortController());
  const abortControllerRefForHighestSeller = useRef(new AbortController());
  const abortControllerRefForArticlePercentage = useRef(new AbortController());

  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };

  const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleUnitCurrency = () => {
    setIsCurrency((prev) => !prev);
  };
  const handlePredictionData = () => {
    setHasPredictionData((prev) => !prev);
  };

  const handleTogglePie = () => {
    setIsPieVisible((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleSubCat = () => {
    setIsSubCategoryVisible((prev) => !prev);
  };

  const handleToggleSubGross = () => {
    setIsSubGrossMarginVisible((prev) => !prev);
  };

  const handleOptionChange = (sub_product) => {
    setSelectedSubProduct(sub_product);
  };

  const handlePercentageChange = (sub_product) => {
    setSubPercentageProduct(sub_product);
  };

  const handleGrossProfitMargin = (sub_product) => {
    setSubGrossMarginProduct(sub_product);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRestaurant(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handlePageChange = (newValue) => {
    setSelectedPage(newValue); // Update the state with the new value
    if (!newValue) return; // If no button is selected, do nothing

    if (newValue === "individual") {
      navigate("/newdashboardtwo", {});
    } else if (newValue === "multiple") {
      navigate("/newsuperdashboardtwo", {});
    }
  };
  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can filter the dashboards to see the whole company, individual
        restaurants or a combination of multiple restaurants
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by a single day, week or a whole month.
        You can easily navigate back and forth with the arrows
      </Typography>
    ),
    3: (
      <Typography variant="body2">
        Toggle to display values in NOK or units
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        This card gives you a summary of the most important product numbers.
        Hover over each number to see an explanation of what it represents and
        how we calculate it
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        This graphs shows how much each product category or product is selling
        for, as a percentage of total sales You can select product category to
        see the products in that category by clicking on the toggle button on
        the upper right corner
      </Typography>
    ),
    6: (
      <Typography variant="body2">
        This pie chart shows how much of gross profit each product category or
        product contributes with. This is a good way to focus on the product’s
        contribution to profit and not only its sales.
      </Typography>
    ),
    7: (
      <Typography variant="body2">
        This is a key metric to understand the profitability of each product and
        product category. You can use the toggle to switch between category and
        the products within a product category
      </Typography>
    ),
    8: (
      <Typography variant="body2">
        Basket size is a number showing how much each customer is buying for.
        This is a key metric for most restaurants.
      </Typography>
    ),
    // ... add more steps as needed
  };

  const highestSellerTooltip = (
    <Typography>
      {isCurrency
        ? "Highest selling product by value: "
        : "Highest selling product by quantity: "}
      {isCurrency
        ? `${new Intl.NumberFormat("nb-NO", {
            style: "currency",
            currency: "NOK",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(Math.round(highestSellerByQuantity.quantity / 500) * 500)}`
        : `${highestSellerByValue.value} units`}
    </Typography>
  );
  //tutorial guide

  const downloadOptions = [
    () =>
      downloadDashboardTwo(
        productMixData,
        productMixPercentageData,
        articleGroupData,
        percentageArticleGroupData,
        averageBasketCost,
        grossProfitMarginGroupData,
        subGrossProfitMarginData,
        selectedRestaurant,
        averageBasketCostData,
        grossProfitKPI,
        takeAwayData,
        highestSellerByQuantity,
        highestSellerByValue
      ),
    // Add other function calls as necessary
  ];
  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 9) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (isXSScreen && helpMode && newCounter > 4) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (isXSScreen && helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });

      setDefaultRestaurants(filteredRestaurants);
      if (primaryRestaurant) {
        const mainRestaurant = filteredRestaurants.filter(
          (restaurant) => restaurant.id === primaryRestaurant
        );
        setSelectedRestaurant([mainRestaurant[0]?.name]);
      }
    }
  }, [primaryRestaurant, setSelectedRestaurant, company]);

  const adjustDates = (date, dataRange, direction) => {
    let newDate = new Date(date);
    const adjustDay = direction === "forward" ? 1 : -1;
    const adjustWeek = direction === "forward" ? 7 : -7;
    const adjustMonth = direction === "forward" ? 1 : -1;

    switch (dataRange) {
      case "today":
        newDate.setDate(newDate.getDate() + adjustDay);
        break;
      case "thisweek":
        newDate.setDate(newDate.getDate() + adjustWeek);
        break;
      case "thismonth":
        newDate.setMonth(newDate.getMonth() + adjustMonth);
        break;
      default:
        break;
    }

    return newDate.toISOString().split("T")[0];
  };

  const handleBackwardClick = () => {
    setAverageBasketCostData([]);
    setGrossProfitKPI([]);
    setDeliveryCost([]);
    setProductMixData([]);
    setAverageBasketCostData([]);
    setArticleGroupData([]);
    setSubGrossMarginProducts([]);
    setGrossProfitMarginGroupData([]);
    setHighestSellerByQuantity({
      product: "N/A",
      quantity: "N/A",
    });
    setHighestSellerByValue({
      product: "N/A",
      value: "N/A",
    });
    const newStartDate = adjustDates(startDate, dataRange, "backward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "backward"));
    }
  };

  const handleForwardClick = () => {
    setAverageBasketCostData([]);
    setGrossProfitKPI([]);
    setDeliveryCost([]);
    setProductMixData([]);
    setAverageBasketCostData([]);
    setArticleGroupData([]);
    setSubGrossMarginProducts([]);
    setGrossProfitMarginGroupData([]);
    setHighestSellerByQuantity({
      product: "N/A",
      quantity: "N/A",
    });
    setHighestSellerByValue({
      product: "N/A",
      value: "N/A",
    });
    const newStartDate = adjustDates(startDate, dataRange, "forward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "forward"));
    }
  };

  const buildFetchUrl = (baseURL, options) => {
    let {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      // dataRange,
      // hasPredictionData,
      newValue,
    } = options;

    let startingDate = startDate;
    let endingDate = endDate;

    // Formatting startDate and endDate
    let formattedStartDate = startingDate;
    let formattedEndDate = endingDate;

    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }

    // Constructing URL with query parameters
    let url = baseURL;
    let isFirstQueryParam = true;

    // Helper function to append parameters
    const appendParam = (key, value) => {
      if (isFirstQueryParam) {
        url += `?${key}=${encodeURIComponent(value)}`;
        isFirstQueryParam = false;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // Append company if present
    if (company) {
      appendParam("company", company);
    }

    // Append start_date and end_date if present
    if (formattedStartDate && formattedEndDate) {
      appendParam("start_date", startDate);
      appendParam("end_date", endDate);
    }

    // Append restaurantNames if present
    if (selectedRestaurant.length > 0) {
      appendParam("restaurants", JSON.stringify(selectedRestaurant));
    }

    // Append newValue if present
    if (newValue) {
      appendParam("period", newValue);
    }
    return url;
  };

  const getDeliveryCost = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/take_out_cost/`,
      urlOptions
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          const totalTakeOutFee = data.results.reduce(
            (acc, entry) => acc + entry.take_out_fee,
            0
          );
          const totalNet = data.results.reduce(
            (acc, entry) => acc + entry.total_net,
            0
          );
          const percentage = (totalTakeOutFee / totalNet) * 100;
          setDeliveryCost(percentage.toFixed(1));
        } else {
          setDeliveryCost([]); // Or any default value you consider appropriate
        }
      })
      .catch((error) => {
        console.error("Error fetching delivery cost data:", error);
        setDeliveryCost(null); // Set to default or error state
      });
  }, [startDate, endDate, selectedRestaurant, range, dataRange, company]);

  //API fetch for gross profit margin for sub product
  const getArticleGrossMarginPercentage = useCallback(() => {
    const subProducts = subGrossMarginProduct;

    if (!subProducts) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/sub_gross_profit_margin`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    Url += `&sub_product=${subProducts}`;

    setGrossProfitMarginLoad(true);
    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const chartData = Object.entries(data.message)
            .filter(([product, _]) => product !== "null") // Filter out entries where the product ID is "null"
            .map(([product, value]) => ({
              Color: getRandomColor(),
              id: product, // Product name will be used as 'id' on the x-axis
              value: parseFloat(value.toFixed(1)), // Use the percentage with 2 decimal points as 'value'
            }));

          setSubGrossProfitMarginData(chartData);
        } else {
          setSubGrossProfitMarginData([]);
          setGrossProfitMarginLoad(false);
        }
        setGrossProfitMarginLoad(false);
      })

      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setGrossProfitMarginLoad(false);
      });
  }, [
    subGrossMarginProduct,
    company,
    range,
    selectedRestaurant,
    startDate,
    endDate,
    dataRange,
  ]);

  //API fetch for product mix data
  const getArticleData = useCallback(() => {
    let range = null;
    range = dataRange === "today" ? "hour" : "day";
    let newValue = range;
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      newValue,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/product_mix`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    abortControllerRefForProductMix.current.abort();
    abortControllerRefForProductMix.current = new AbortController();
    setProdBarLoad(true);
    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForProductMix.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let resultsToUse = hasPredictionData
            ? data.message.prediction
            : data.message.historical;
          // if (resultsToUse.length <= 0) {
          //   setSubProducts([]);
          //   setProductMixData([]);
          //   setProdBarLoad(false);
          //   return;
          // }
          let sub_products = [];
          Object.entries(resultsToUse).map((map) => {
            const sub_keys = Object.keys(map[1]);
            for (const index in sub_keys) {
              const key = sub_keys[index];
              if (!sub_products.includes(key)) {
                sub_products.push(key);
              }
            }
            return sub_products;
          });
          setSubProducts(sub_products);

          const chartData = Object.entries(resultsToUse).map(
            ([actual_date, values]) => {
              let date;
              const totalNetData = {};
              const totalQuantity = {};
              Object.entries(values).forEach(
                ([category, { total_net, quantity_sold }]) => {
                  totalNetData[category] = total_net;
                  totalQuantity[category] = quantity_sold;
                }
              );

              if (newValue === "week") {
                const [year, week] = actual_date.split(" ");
                const newDate = new Date(Date.UTC(year, 0)); // January 1st of the year
                const weekOffset = week * 7; // Calculate the number of days to add for the week
                newDate.setUTCDate(newDate.getUTCDate() + weekOffset);
                const transformed_date = new Date(newDate);
                const yyyy = transformed_date.getUTCFullYear();
                const mm = String(transformed_date.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                ); // Month is 0-based, so we add 1 and pad with 0 if needed
                const dd = String(transformed_date.getUTCDate()).padStart(
                  2,
                  "0"
                );
                const formatted_date = `${yyyy}-${mm}-${dd}`;
                date = formatted_date;
              } else {
                date = actual_date;
              }
              return {
                date,
                ...(isCurrency ? totalNetData : totalQuantity),
              };
            }
          );
          setProductMixData(chartData);
        } else {
          setSubProducts([]);
          setProductMixData([]);
        }
        setProdBarLoad(false);
      })

      .catch((error) => {
        setProdBarLoad(false);
        console.error("Error fetching sales data:", error);
      });
  }, [
    startDate,
    endDate,
    selectedRestaurant,
    company,
    isCurrency,
    dataRange,
    hasPredictionData,
  ]);

  const getArticleGroupData = useCallback(() => {
    let range = null;
    range = dataRange === "today" ? "hour" : "day";
    let newValue = range;
    const subProducts = selectedSubProduct;
    if (!subProducts) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      newValue,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/sub_product_mix`,
      urlOptions
    );

    Url += `&sub_product=${subProducts}`;

    setProdBarLoad(true);

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const productSales = {};
          let resultsToUse = hasPredictionData
            ? data.message.prediction
            : data.message.historical;
          // if (resultsToUse.length <= 0) {
          //   setArticleGroupData([]);
          //   setProdBarLoad(false);
          //   return;
          // }
          Object.values(resultsToUse).forEach((dateData) => {
            Object.entries(dateData).forEach(([product, sales]) => {
              if (!productSales[product]) {
                productSales[product] = { total: 0, count: 0 };
              }
              productSales[product].total += sales;
              productSales[product].count++;
            });
          });

          // Calculate the average sales for each product
          Object.entries(productSales).forEach(
            ([product, { total, count }]) => {
              productSales[product].average = total / count;
            }
          );

          const chartData = Object.entries(resultsToUse).map(
            ([actual_date, values]) => {
              let date;
              const totalNetData = {};
              const totalQuantity = {};

              Object.entries(values).forEach(
                ([category, { total_net, quantity_sold }]) => {
                  totalNetData[category] = total_net;
                  totalQuantity[category] = quantity_sold;
                }
              );
              if (newValue === "week") {
                const [year, week] = actual_date.split(" ");
                const newDate = new Date(Date.UTC(year, 0)); // January 1st of the year
                const weekOffset = week * 7; // Calculate the number of days to add for the week
                newDate.setUTCDate(newDate.getUTCDate() + weekOffset);
                const transformed_date = new Date(newDate);
                const yyyy = transformed_date.getUTCFullYear();
                const mm = String(transformed_date.getUTCMonth() + 1).padStart(
                  2,
                  "0"
                ); // Month is 0-based, so we add 1 and pad with 0 if needed
                const dd = String(transformed_date.getUTCDate()).padStart(
                  2,
                  "0"
                );
                const formatted_date = `${yyyy}-${mm}-${dd}`;
                date = formatted_date;
              } else {
                date = actual_date;
              }
              return {
                date,
                ...(isCurrency ? totalNetData : totalQuantity),
              };
            }
          );
          setArticleGroupData(chartData);
        } else {
          setArticleGroupData([]);
        }
        setProdBarLoad(false);
      })

      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setProdBarLoad(false);
      });
  }, [
    selectedSubProduct,
    startDate,
    endDate,
    company,
    selectedRestaurant,
    isCurrency,
    dataRange,
    hasPredictionData,
  ]);

  //API fetch for product sub category
  const getArticleGroupPercentageData = useCallback(() => {
    const subProducts = subPercentageProduct;
    if (!subProducts) {
      return;
    }
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/percentage_sub_product_mix`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    Url += `&sub_product=${subProducts}`;

    setGrossProfitLoad(true);

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const chartData = Object.entries(data.message).map(
            ([product, value]) => ({
              Color: getRandomColor(),
              id: product, // Product name will be used as 'id' on the x-axis
              value: parseFloat(value.toFixed(1)), // Use the percentage with 2 decimal points as 'value'
            })
          );

          setPercentageArticleGroupData(chartData);
        } else {
          setPercentageArticleGroupData([]);
        }
        // setPercentageArticleGroupData([]);
        setGrossProfitLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setGrossProfitLoad(false);
      });
  }, [
    subPercentageProduct,
    startDate,
    endDate,
    range,
    company,
    selectedRestaurant,
    dataRange,
  ]);

  //Gross Profit Margin Data
  const getGrossProfitMarginGroupData = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };

    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/gross_profit_margin`,
      urlOptions
    );

    setGrossProfitMarginLoad(true);
    abortControllerRefForGrossProfitMargin.current.abort();
    abortControllerRefForGrossProfitMargin.current = new AbortController();
    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForGrossProfitMargin.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let totalValue = 0;
          let count = 0;
          Object.values(data.message).forEach((value) => {
            totalValue += value;
            count++;
          });
          const overallAverageMargin = count > 0 ? totalValue / count : 0;
          let sub_products = [];
          // Modify the chart data here to have 'id' and 'value' properties
          const chartData = Object.entries(data.message).map(
            ([product, value]) => {
              sub_products.push(product);

              return {
                Color: getRandomColor(),
                id: product, // Product name will be used as 'id' on the x-axis
                value: parseFloat(value.toFixed(1)), // Use the percentage with 2 decimal points as 'value'
              };
            }
          );

          setSubGrossMarginProducts(sub_products);
          setGrossProfitMarginGroupData(chartData);
          setGrossProfitKPI(overallAverageMargin.toFixed(1));
        } else {
          setSubGrossMarginProducts([]);
          setGrossProfitMarginGroupData([]);
          setGrossProfitKPI([]);
        }
        setGrossProfitMarginLoad(false);
      })

      .catch((error) => {
        // setGrossProfitMarginLoad(false);
        // console.error("Error fetching gross profit margin data:", error);
        if (error.name !== "AbortError") {
          console.error(error);
          setGrossProfitMarginLoad(false);
        }
      });
  }, [startDate, endDate, company, selectedRestaurant, range, dataRange]);

  //API fetch for average basket size
  const getAverageBasketSize = useCallback(() => {
    let newValue = range;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/basket_cost/`,
      urlOptions
    );
    setBasketLoad(true);
    abortControllerRefForAverageBasket.current.abort();
    abortControllerRefForAverageBasket.current = new AbortController();

    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForAverageBasket.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let totalAverage = 0;
          let count = 0;
          data.message.forEach((entry) => {
            if (isCurrency) {
              if (entry.average) {
                totalAverage += entry.average;
                count++;
              }
            } else {
              if (entry.average_units) {
                totalAverage += entry.average_units;
                count++;
              }
            }
          });

          let overallAverage = count > 0 ? totalAverage / count : 0;
          if (overallAverage < 0) {
            overallAverage = NaN;
          }
          setAverageBasketCostData(overallAverage.toFixed(0));

          const absoluteBasketCostData = {
            ...data.message.reduce((result, entry) => {
              const { date, restaurant, average, company, average_units } =
                entry;
              let date_period;
              let y_value = isCurrency ? average : average_units;
              if (!isCurrency && y_value < 0) {
                y_value = null;
              }
              if (newValue === "week") {
                let [year, week] = date.split(" ");
                let actualYear = parseInt(year, 10);
                const firstJan = new Date(Date.UTC(actualYear, 0, 1)); // January 1st of the given year

                // Calculate the first Monday of the year
                const dayOffset = (1 - firstJan.getUTCDay() + 7) % 7;
                const firstMonday = new Date(firstJan);
                firstMonday.setDate(firstJan.getDate() + dayOffset);

                // If the date is before the first Monday, adjust the week and year

                const weekOffset = (week - 1) * 7;
                date_period = new Date(firstMonday);
                date_period.setUTCDate(firstMonday.getUTCDate() + weekOffset);
              } else if (newValue === "month") {
                const [year, month] = date.split(" ");

                date_period = new Date(year, parseInt(month) - 1, 23); // Set to the last day of the given month
              } else {
                date_period = new Date(date);
              }

              if (selectedRestaurant.length > 0) {
                return {
                  ...result,
                  [restaurant]: {
                    id: restaurant,
                    data: [
                      ...(result[restaurant]?.data || []),
                      {
                        x: new Date(date_period),
                        y: y_value ? parseFloat(y_value).toFixed(0) : 0,
                      },
                    ],
                  },
                };
              } else {
                return {
                  ...result,
                  [company]: {
                    id: company,
                    data: [
                      ...(result[company]?.data || []),
                      {
                        x: new Date(date_period),
                        y: y_value ? parseFloat(y_value).toFixed(0) : 0,
                      },
                    ],
                  },
                };
              }
            }, {}),
          };
          setAverageBasketCost(absoluteBasketCostData);
        } else {
          setAverageBasketCost([]);
          setAverageBasketCostData([]);
        }
        setBasketLoad(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setBasketLoad(false);
        }
      });
  }, [
    range,
    startDate,
    endDate,
    selectedRestaurant,
    company,
    dataRange,
    isCurrency,
  ]);

  // API to fetch highest selling product's amount
  const getHighestSellingProduct = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/highest_selling_product`,
      urlOptions
    );
    setHighestLoad(true);

    abortControllerRefForHighestSeller.current.abort();
    abortControllerRefForHighestSeller.current = new AbortController();
    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForHighestSeller.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const highest_selling_product_quant = data.message.quantity;
          const highest_selling_product_amount = data.message.amount;

          const highestValueProduct = highest_selling_product_quant.find(
            (item) => item.total_sales_value
          );
          const highestQuantityProduct = highest_selling_product_amount.find(
            (item) => item.total_sales_value
          );

          setHighestSellerByValue({
            product: highestValueProduct
              ? highestValueProduct.article_group
              : "N/A",
            value: highestValueProduct
              ? highestValueProduct.total_sales_value
              : "N/A",
          });
          setHighestSellerByQuantity({
            product: highestQuantityProduct
              ? highestQuantityProduct.article_group
              : "N/A",
            quantity: highestQuantityProduct
              ? highestQuantityProduct.total_sales_value
              : "N/A",
          });
        } else {
          setHighestSellerByQuantity({
            product: "N/A",
            quantity: "N/A",
          });
          setHighestSellerByValue({
            product: "N/A",
            value: "N/A",
          });
          setHighestLoad(false);
        }
        setHighestLoad(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setHighestLoad(false);
        }
      });
  }, [startDate, endDate, selectedRestaurant, dataRange, company]);

  //API fetch for the piechart data
  const getArticlePercentageData = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/product_mix_percentage/`,
      urlOptions
    );
    setGrossProfitLoad(true);
    abortControllerRefForArticlePercentage.current.abort();
    abortControllerRefForArticlePercentage.current = new AbortController();
    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForArticlePercentage.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          let sub_products = [];
          // Modify the chart data here to have 'id' and 'value' properties
          const chartData = Object.entries(data.message).map(
            ([product, value]) => {
              sub_products.push(product);

              return {
                Color: getRandomColor(),
                id: product, // Product name will be used as 'id' on the x-axis
                value: parseFloat(value.toFixed(1)), // Use the percentage with 2 decimal points as 'value'
              };
            }
          );
          setSubPercentageProducts(sub_products);
          setProductMixPercentageData(chartData);
        } else {
          setSubPercentageProducts([]);
          setProductMixPercentageData([]);
        }
        setGrossProfitLoad(false);
      })
      .catch((error) => {
        // console.error("Error fetching sales data:", error);
        // setGrossProfitLoad(false);
        if (error.name !== "AbortError") {
          console.error(error);
          setGrossProfitLoad(false);
        }
      });
  }, [range, startDate, endDate, selectedRestaurant, dataRange, company]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setAverageBasketCost([]);
      setProductMixData([]);
      setAverageBasketCostData([]);
    } else {
      const timer = setTimeout(() => {
        getAverageBasketSize();
        getArticleData();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [getAverageBasketSize, getArticleData, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setGrossProfitKPI([]);
      setTakeAwayData([]);
      setDeliveryCost([]);
      setGrossProfitMarginGroupData([]);
      // setAverageBasketCostData([]);
    } else {
      const timer = setTimeout(() => {
        getGrossProfitMarginGroupData();
        getDeliveryCost();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [
    // getArticlePercentageData,
    getGrossProfitMarginGroupData,

    selectedPage,
    selectedRestaurant,
    getDeliveryCost,
  ]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setProductMixPercentageData([]);
    } else {
      const timer = setTimeout(() => {
        getArticlePercentageData();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [getArticlePercentageData, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setPercentageArticleGroupData([]);
    } else {
      const timer = setTimeout(() => {
        getArticleGroupPercentageData();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [getArticleGroupPercentageData, selectedPage, selectedRestaurant]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setArticleGroupData([]);
      setHighestSellerByQuantity({
        product: "N/A",
        quantity: "N/A",
      });
      setHighestSellerByValue({
        product: "N/A",
        value: "N/A",
      });
    } else {
      const timer = setTimeout(() => {
        getArticleGroupData();
        getHighestSellingProduct();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [
    getArticleGroupData,
    getHighestSellingProduct,
    selectedPage,
    selectedRestaurant,
  ]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      // setPercentageArticleGroupData([]);
      setSubGrossProfitMarginData([]);
    } else {
      const timer = setTimeout(() => {
        // getArticleGroupPercentageData();
        getArticleGrossMarginPercentage();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [
    // getArticleGroupPercentageData,
    getArticleGrossMarginPercentage,
    selectedPage,
    selectedRestaurant,
  ]);

  useEffect(() => {
    return () => {
      if (abortControllerRefForArticlePercentage.current) {
        abortControllerRefForArticlePercentage.current.abort();
      }

      if (abortControllerRefForProductMix.current) {
        abortControllerRefForProductMix.current.abort();
      }

      if (abortControllerRefForGrossProfitMargin.current) {
        abortControllerRefForGrossProfitMargin.current.abort();
      }

      if (abortControllerRefForHighestSeller.current) {
        abortControllerRefForHighestSeller.current.abort();
      }
      if (abortControllerRefForAverageBasket.current) {
        abortControllerRefForAverageBasket.current.abort();
      }
    };
  }, []);

  // useEffect(() => {
  //   if (subGrossProfitMarginData.length < 0) {
  //     setIsSubGrossMarginVisible(false);
  //   }
  // }, [subGrossProfitMarginData, grossProfitMarginLoad]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "1" },
        }}
      >
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 1, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "10%", xs: "20%" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 1}
            >
              <Box
                sx={{
                  ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  alignItems: "center",
                  mt: { lg: 0, xs: 1 },
                  gap: { lg: 0, xs: 1 },
                  // border: "solid 1px blue",
                }}
              >
                {/* {selectedPage === "multiple" ? ( */}
                <FormControl sx={{ width: 200, m: 0 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    {selectedPage === "company" ? company : "All Restaurants"}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedPage === "company" ? [] : selectedRestaurant}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        label={
                          selectedPage === "company"
                            ? company
                            : "All Restaurants"
                        }
                      />
                    }
                    // renderValue={(selected) => selected.join(", ")}
                    renderValue={(selected) =>
                      selected
                        .map((name) => getRestaurantNameById(name))
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                    disabled={selectedPage === "company"}
                    sx={{
                      "& .MuiSelect-select": {
                        // height: '3rem',
                        padding: "0",
                        textIndent: "0.25em",
                        textAlign: "center",
                      },
                      background: "#F8F8F8",
                      mx: ".5rem",
                      height: "3rem",
                      color: "black",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    }}
                  >
                    {/* {restaurants.map((restaurant) => (
                        <MenuItem key={restaurant.name} value={restaurant.name}>
                          <Checkbox
                            checked={
                              restaurantNames.indexOf(restaurant.name) > -1
                            }
                          />
                          <ListItemText primary={restaurant.name} />
                        </MenuItem>
                        ))} */}

                    {defaultRestaurants.map((restaurant) => (
                      <MenuItem key={restaurant.id} value={restaurant.name}>
                        <Checkbox
                          checked={selectedRestaurant.includes(restaurant.name)}
                        />
                        <ListItemText primary={restaurant.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <ToggleButtonGroup
                    value={selectedPage}
                    exclusive
                    onChange={(event) => {
                      handlePageChange(event.target.value);
                      setSelectedRestaurant([]);
                      setArticleGroupData([]);
                      setAverageBasketCost([]);
                      setAverageBasketCostData([]);
                      setProductMixData([]);
                      setIsSubCategoryVisible(false);
                      setPercentageArticleGroupData([]);
                      setGrossProfitMarginGroupData([]);
                      setSubGrossProfitMarginData([]);
                    }}
                    sx={{
                      "& .MuiToggleButton-root": {
                        // height: "3rem",
                        background: "#F8F8F8",
                        "&.Mui-selected": {
                          background: "#D6D6D6", // Darker shade for the selected button
                        },
                      },
                      mx: ".5rem",
                    }}
                    disabled={!isAdmin}
                    aria-label="Range"
                  >
                    <ToggleButton value="company" aria-label="company">
                      Company
                    </ToggleButton>
                    <ToggleButton value="multiple" aria-label="multiple">
                      Restaurants
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 2}
            >
              <Box
                sx={{
                  ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mt: { lg: 3 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px red",
                    height: "100%",
                    width: { xs: "80%", lg: "100%" },
                  }}
                >
                  <ArrowBackIcon
                    onClick={handleBackwardClick}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size
                      cursor: "pointer", // Ensures the cursor is a pointer // Ensures the cursor is a pointer
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                  <FormControl sx={{ width: { lg: 250, xs: 300 }, m: 0 }}>
                    {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                    <ToggleButtonGroup
                      value={dataRange}
                      // The range is the same. Just hour for today.
                      //  Otherwise it's just the startdate and enddate that need to be adjusted accordingly
                      disabled={prodBarLoad}
                      exclusive
                      onChange={(e) => {
                        setDataRange(e.target.value);
                        let startingDate;
                        let endingDate;
                        switch (e.target.value) {
                          case "today":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            break;
                          case "thisweek":
                            // Find the start of the week (Monday)
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate() -
                                  now.getUTCDay() +
                                  (now.getUTCDay() === 0 ? -6 : 1)
                              )
                            );
                            // Find the end of the week (Sunday)
                            endingDate = new Date(startingDate);
                            endingDate.setDate(startingDate.getDate() + 6); // Add 6 days to get to Sunday
                            break;

                          case "thismonth":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                1
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth() + 1,
                                0
                              ) // Set to the last day of the current month
                            );
                            break;
                          default:
                            // Handle any other cases or default behavior
                            break;
                        }
                        setStartDate(startingDate?.toISOString().split("T")[0]);
                        setEndDate(endingDate?.toISOString().split("T")[0]);
                      }}
                      sx={{
                        "& .MuiToggleButton-root": {
                          height: "3rem",
                          background: "#F8F8F8",
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                      }}
                      aria-label="Range"
                    >
                      <ToggleButton value="today" aria-label="Today">
                        Day
                      </ToggleButton>
                      <ToggleButton value="thisweek" aria-label="This Week">
                        Week
                      </ToggleButton>
                      <ToggleButton value="thismonth" aria-label="This Month">
                        Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                  <ArrowForwardIcon
                    onClick={handleForwardClick}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                </Box>
                <FormatDesiredDate
                  startDate={startDate}
                  endDate={endDate}
                  dataRange={dataRange}
                />
              </Box>
            </Tooltip>

            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 3}
            >
              <Tooltip
                title="Toggle between Units and NOK"
                arrow
                placement="bottom"
              >
                <Box
                  sx={{
                    ...(counter === 3 && helpMode ? getHighlightedStyle() : {}),

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "#515151",
                      fontSize: "12px",
                    }}
                  >
                    Units
                  </Typography>
                  <Switch checked={isCurrency} onChange={handleUnitCurrency} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "#515151",
                      fontSize: "12px",
                    }}
                  >
                    NOK
                  </Typography>
                </Box>
              </Tooltip>
            </Tooltip>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isXSScreen && <LastUpdate />}
            </Box>
            <Box
              sx={{
                marginBottom: { xs: 0, lg: 0 }, // Adds bottom margin on xs screens
              }}
            >
              <SettingsDialog
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                range={range}
                downloadOptions={downloadOptions}
                hasPredictionData={hasPredictionData}
                handlePredictionData={handlePredictionData}
                showPredictionOptions={true} // or false depending on context
                showTrendsOptions={false}
                setCompany={setCompany}
                company={company}
                email={email}
                // showPredictionToggle={showPredictionToggle}
              />
              <HelpModeButton
                isXSScreen={isXSScreen}
                handleHelpMode={handleHelpMode}
                helpMode={helpMode}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              mt: { lg: 0, xs: "1rem" },
              justifyContent: { lg: "flex-start" },
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "45%", xs: "100vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 4}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 4 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "25%", xs: "90%" },
                  gap: { lg: 2, xs: 3 },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 4 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "20rem" },
                  fontFamily: "Montserrat",
                  paddingY: { lg: 0, xs: 1 },
                }}
              >
                <Box>
                  <TitleStyle tooltipKey="quick_overview">
                    Quick Overview
                  </TitleStyle>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    paddingBottom: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "100%",
                    width: "90%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                      }}
                    >
                      {isCurrency ? "Basket Size" : "Units per Basket"}
                    </Typography>
                    <TooltipCreate tooltipKey="basket_size">
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        {basketLoad ? (
                          <KPILoad />
                        ) : isCurrency ? (
                          new Intl.NumberFormat("nb-NO", {
                            style: "currency",
                            currency: "NOK",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(averageBasketCostData)
                        ) : (
                          `${averageBasketCostData} units`
                        )}
                      </Typography>
                    </TooltipCreate>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                      }}
                    >
                      Gross Profit Margin
                    </Typography>
                    <TooltipCreate tooltipKey="gross_profit_margin">
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {grossProfitMarginLoad ? (
                          <KPILoad />
                        ) : (
                          `${grossProfitKPI} %`
                        )}
                      </Typography>
                    </TooltipCreate>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                      }}
                    >
                      Third Party Delivery Cost
                    </Typography>
                    <TooltipCreate tooltipKey="third_party_delivery_kpi">
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {prodBarLoad ? <KPILoad /> : `${deliveryCost} %`}
                      </Typography>
                    </TooltipCreate>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        fontWeight: 400,
                        marginRight: 2,
                        textAlign: "left",
                      }}
                    >
                      Highest Seller
                    </Typography>

                    <TooltipCreate
                      content={highestSellerTooltip}
                      position="top"
                    >
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        {highestLoad ? (
                          <KPILoad />
                        ) : isCurrency ? (
                          highestSellerByQuantity.product
                        ) : (
                          highestSellerByValue.product
                        )}
                      </Typography>
                    </TooltipCreate>
                  </Box>
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 5}
            >
              <Box
                sx={{
                  ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "75%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 5 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                  // mb: { lg: 0, xs: "2rem" },
                  // border: "1px solid red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                    // border:"solid red 1px"
                  }}
                >
                  <TitleStyle tooltipKey="product_sales">
                    Product Sales
                  </TitleStyle>

                  {(productMixData.length > 0 || isEmpty(productMixData)) && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle between Product Category and Product"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                      >
                        <Typography variant="h7">
                          {/* <CategoryIcon /> */}
                        </Typography>
                        <Switch
                          checked={isSubCategoryVisible}
                          onChange={handleToggleSubCat}
                        />
                        <Typography variant="h7">
                          {/* <LocalOfferIcon /> */}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                </Box>

                {isSubCategoryVisible ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                      // border:"solid 1px red"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControl sx={{ minWidth: 140 }}>
                        <InputLabel id="select-label">Select Option</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          size="small"
                          onChange={(e) => {
                            handleOptionChange(e.target.value);
                          }}
                          label="Select Option"
                          value={selectedSubProduct}
                        >
                          {subProducts.map((sub) => (
                            <MenuItem value={sub}>{sub}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        height: { lg: "80%", xs: "25rem", xl: "80%" },
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                        // border: "solid red 1 px",
                      }}
                    >
                      {prodBarLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "50%",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <ProdBarChart
                          data={articleGroupData}
                          isDetailedChart={false}
                          range={range}
                          isXSScreen={isXSScreen}
                          isMinimal={true}
                          isPercentage={false}
                          isCurrency={isCurrency}
                          dataRange={dataRange}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "80%", xs: "25rem" },
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    {prodBarLoad ? (
                      <Box
                        sx={{
                          display: "flex",
                          height: "50%",
                        }}
                      >
                        <ChartLoading />
                      </Box>
                    ) : (
                      <ProdBarChart
                        data={productMixData}
                        isDetailedChart={false}
                        range={range}
                        isXSScreen={isXSScreen}
                        isMinimal={true}
                        isPercentage={false}
                        isCurrency={isCurrency}
                        dataRange={dataRange}
                      />
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "60%", xs: "180vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 6}
            >
              <Box
                sx={{
                  ...(counter === 6 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "35%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 6 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                  // border: "1px solid red",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                    // border: "solid red 1px",
                  }}
                >
                  <TitleStyle tooltipKey="gross_profit_contribution">
                    Gross Profit Contribution
                  </TitleStyle>

                  {(productMixPercentageData.length > 0 ||
                    isEmpty(productMixPercentageData)) && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle between Product Category and Product"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                      >
                        <Switch
                          checked={isPieVisible}
                          onChange={handleTogglePie}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                {isPieVisible ? (
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                      // border: "1px solid red",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControl sx={{ minWidth: 140 }}>
                        <InputLabel id="select-label">Select Option</InputLabel>
                        <Select
                          labelId="select-label"
                          id="select"
                          size="small"
                          onChange={(e) => {
                            handlePercentageChange(e.target.value);
                          }}
                          value={subPercentageProduct}
                          label="Select Option"
                        >
                          {subPercentageProducts.map((sub) => (
                            <MenuItem value={sub}>{sub}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        height: { lg: "90%", xs: "20rem", xl: "80%" },
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                        // border: "solid red 1px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "15rem",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          // border: "solid red 1px",
                        }}
                      >
                        {grossProfitLoad ? (
                          <Box
                            sx={{
                              display: "flex",
                              height: "50%",
                            }}
                          >
                            <ChartLoading />
                          </Box>
                        ) : (
                          <PieChart
                            data={percentageArticleGroupData}
                            isDetailedChart={false}
                            hasDifferentColor={true}
                            isMinimal={false}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  // </Box>
                  <Box
                    sx={{
                      display: "flex",
                      height: { lg: "90%", xs: "25rem" },
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      // border: "solid red 1px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "90%",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                        // border: "solid red 1px",
                      }}
                    >
                      {grossProfitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "50%",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <PieChart
                          data={productMixPercentageData}
                          isDetailedChart={false}
                          hasDifferentColor={true}
                          isMinimal={true}
                        />
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Tooltip>

            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 7}
            >
              <Box
                sx={{
                  ...(counter === 7 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "40%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 7 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <TitleStyle tooltipKey="gross_profit_margin">
                    Gross Profit Margin
                  </TitleStyle>

                  {(grossProfitMarginGroupData.length > 0 ||
                    isEmpty(grossProfitMarginGroupData)) && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle between Product Category and Product"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                      >
                        <Switch
                          checked={isSubGrossMarginVisible}
                          onChange={handleToggleSubGross}
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>

                {isSubGrossMarginVisible && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl sx={{ minWidth: 140 }}>
                      <InputLabel id="select-label">Select Option</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        onChange={(e) =>
                          handleGrossProfitMargin(e.target.value)
                        }
                        value={subGrossMarginProduct}
                        size="small"
                        label="Select Option"
                      >
                        {subGrossMarginProducts.map((sub) => (
                          <MenuItem key={sub} value={sub}>
                            {sub}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                {/* Loading or Chart Rendering */}
                <Box
                  sx={{
                    display: "flex",
                    height: { lg: "90%", xs: "25rem" },
                    width: "90%",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid red 1px",
                  }}
                >
                  {grossProfitMarginLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : isSubGrossMarginVisible ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: isSubGrossMarginVisible
                          ? { lg: "80%", xs: "20rem" }
                          : { lg: "80%", xs: "25rem" },
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                        // border: "solid red 1px",
                      }}
                    >
                      <PerBarChart
                        data={subGrossProfitMarginData}
                        isDetailedChart={false}
                        isHorizontal={true}
                        hasDifferentColor={true}
                        isMinimal={false}
                        isGrossProfit={true}
                        // isNewTooltip={true}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        height: { lg: "90%", xs: "25rem" },
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                        // border: "solid red 1px",
                      }}
                    >
                      <PerBarChart
                        data={grossProfitMarginGroupData}
                        isDetailedChart={false}
                        hasDifferentColor={true}
                        isMinimal={true}
                        isGrossProfit={true}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 8}
            >
              <Box
                sx={{
                  ...(counter === 8 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "40%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 8 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // fontFamily: "Montserrat",
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <TitleStyle tooltipKey="basket_size">
                    {" "}
                    {isCurrency
                      ? "Average Basket Size"
                      : "Average Units per Basket"}
                  </TitleStyle>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "85%",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {basketLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : (
                    <LineChart
                      data={averageBasketCost}
                      isNotPercentage={true}
                      range={range}
                      startDate={startDate}
                      endDate={endDate}
                      isXSScreen={isXSScreen}
                      isMinimal={false}
                      isCurrency={isCurrency}
                      // useKpiFormat={true}
                    />
                  )}
                </Box>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            width: { lg: "50vw", xs: "95vw" },
            height: { lg: "70vh", xs: "80vh" },
            maxWidth: "none", // This will help override default maxWidth values
            maxHeight: "none", // This will help override default maxHeight values
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DialogTitle sx={{ flexGrow: 1 }}>Settings</DialogTitle>

          <CloseIcon
            onClick={handleCloseDialog}
            sx={{
              mr: "1rem",
              cursor: "pointer", // To indicate that the icon is clickable
              borderRadius: "50%", // To make the background change rounded, like an icon
              p: "8px",
              transition: "background-color 0.3s", // Smooth transition for the hover effect
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Slight gray on hover
              },
            }}
          />
        </Box>
      </Dialog>
      <Dialog
        open={openHelpDialog}
        onClose={() => setOpenHelpDialog(false)}
        aria-labelledby="help-dialog-title"
        aria-describedby="help-dialog-description"
      >
        <DialogTitle id="help-dialog-title">{"Help Information"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="help-dialog-description">
            This help button is designed to provide you with additional
            information and guidance on how to use the features of this
            application. Clicking this button will display useful tips and FAQs
            to help you navigate and make the most of the app's functionalities.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHelpDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarAlert
        open={open}
        severity={severity}
        message={message}
        onClose={handleClose}
      />
      {helpMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: { lg: "180px", xs: "280px" },
            position: "fixed", // Make it absolute to position at the bottom
            bottom: 20,
            right: 20,
            padding: "10px",
            // border:"solid 1px red"
          }}
        >
          <NavigationButtons
            isXSScreen={isXSScreen}
            setHelpMode={setHelpMode}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            counter={counter}
            maxSteps={maxSteps}
          />
        </Box>
      )}
    </Box>
  );
};

export default NewSuperDashboardTwo;
