import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  // Grid,
  //    Container,
  // Card,
  Typography,
  // TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Tooltip,
  // Zoom,
  useMediaQuery,
  // Collapse,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  // TextField,
  // Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import { useNavigate } from "react-router-dom";
// import downloadDashboardOne from "../utils/ExcelDownloads/DownloadDashboardOne";
// import PerBarChart from "../components/BarCharts/PerBarChart";
// import getRandomColor from "../utils/RandomColor";
// import SettingsIcon from "@mui/icons-material/Settings";
// import CloseIcon from "@mui/icons-material/Close";
import downloadSuperDashboardOne from "../utils/ExcelDownloads/DownloadSuperDashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChartLoading from "../components/Loading/ChartLoading";
import LineChart from "../components/LineCharts/LineChart";
import SnackbarAlert from "../utils/SnackbarAlert";
import LastUpdate from "../utils/LastUpdate";
import SlicesLineChart from "../components/LineCharts/SlicesLineChart";
import PointHourlyLineChart from "../components/LineCharts/PointHourlyLineChart";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
import Prefix from "../utils/PrefixLink";
import HourlySlicesLineChart from "../components/LineCharts/HourlySlicesLineChart";
import SettingsDialog from "../components/SettingsDialog";
// import formatDateToDesiredFormat from "../utils/FormatDesiredDate";
import FormatDesiredDate from "../utils/FormatDesiredDate";
// import OverviewTableStaff from "./DashboardComponents/OverviewTableStaff";
// import TooltipCreate from "../components/Tooltips/Tooltip";
import TitleStyle from "../components/Style/Title";
import HelpModeButton from "../components/HelpModeButton";
import YipsOverviewTable from "./DashboardComponents/YipsOverviewTable";
// import StyledToggleButtonGroup from "./DashboardComponents/ToggleButtonStyle";
// import DeliveryChart from "../components/BarCharts/DeliveryBarChart";

const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// const comp = localStorage.getItem("company");
const token = localStorage.getItem("token");
// const default_restaurant = JSON.parse(
//   localStorage.getItem("user")
// )?.restaurant_name;
const isAdmin = JSON.parse(localStorage.getItem("user"))?.company_admin;

const prefix = Prefix();

const YipsNewSuperDashboard = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant,
  hasPredictionData,
  setHasPredictionData,
  range,
  company,
  setCompany,
}) => {
  const latest_created_at = localStorage.getItem("latest_records");

  const now = latest_created_at ? new Date(latest_created_at) : new Date();

  // const [restaurants, setRestaurants] = useState([])
  // const [restaurantNames, setRestaurantNames] = useState(
  //   default_restaurant ? [default_restaurant] : []
  // );
  // const [company, setCompany] = useState(comp);
  const user = JSON.parse(localStorage.getItem("user"));
  const email = user.email;
  const primaryRestaurant = user?.primary_restaurant;

  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  // const [employeeCountData, setEmployeeCountData] = useState([]);
  // const [selectedREstaurant, setSelectedRestaurantNames] = useState([]);
  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };
  // const [deliveryCostLoad, setDeliveryCostLoad] = useState(false);
  // const [sickCostLoad, setSickCostLoad] = useState(false);

  // const [empCostLoad, setEmpCostLoad] = useState(false);
  // const [avgEmpProdLoad, setAvgEmpProdLoad] = useState(false);
  const [staffCostLoad, setStaffCostLoad] = useState(false);

  // const [selectedPage, setSelectedPage] = useState(
  //   isAdmin ? "company" : "multiple"
  // );
  const [staffData, setStaffData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  // const [deliveryCost, setDeliveryCost] = useState([]);
  const [employeeProductivity, setEmployeeProductivity] = useState([]);
  const [absoluteStaffSalesData, setAbsoluteStaffSalesData] = useState([]);
  const [sickCostData, setSickCostData] = useState([]);
  // const [dataRange, setDataRange] = useState("today");
  // const [openDialog, setOpenDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [averageSickCost, setAverageSickCost] = useState([]);
  const [averageEmployeeCost, setAverageEmployeeCost] = useState([]);
  const [averageEmployeeProductivity, setAverageEmployeeProductivity] =
    useState([]);
  const [statStaffData, setStatStaffData] = useState([]);
  console.log(statStaffData, "staff cost");
  // const [averageDeliveryCost, setAverageDeliveryCost] = useState([]);

  // const [hasPredictionData, setHasPredictionData] = useState(false);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState(null);
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = 9;
  // const navigate = useNavigate();

  const theme = useTheme();

  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const [intervalValue, setIntervalValue] = useState("20000-40000");

  const abortControllerRefForStaffData = useRef(new AbortController());
  // const abortControllerRefForEmployeeProd = useRef(new AbortController());
  // const abortControllerRefForEmployeeCount = useRef(new AbortController());

  // const generateOptions = () => {
  //   let options = [];
  //   for (let i = 0; i <= 120000; i += 20000) {
  //     options.push(`${i}-${i + 20000}`);
  //   }
  //   return options;
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  // const handleOpenSettingsDialog = () => {
  //   setOpenDialog(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleIntervalChange = (event) => {
  //   setIntervalValue(event.target.value);
  // };

  const handlePredictionData = () => {
    setHasPredictionData((prev) => !prev);
  };
  // const handleToggleKPI = () => {
  //   setIsFlatKPIVisible((prev) => !prev);
  // };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRestaurant(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event

  //   // 'value' is the new selection from the Select component
  //   // Convert to an array if it's not already (for multiple select)
  //   const newSelection = typeof value === "string" ? value.split(",") : value

  //   // Ensure the primary restaurant is always included
  //   const updatedSelection = primaryRestaurant
  //     ? [...new Set([primaryRestaurant, ...newSelection])]
  //     : newSelection

  //   setRestaurantNames(updatedSelection)
  // }

  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 9) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (isXSScreen && helpMode && newCounter > 4) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };

  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can filter the dashboards to see the whole company, individual
        restaurants or a combination of multiple restaurants
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by a single day, week or a whole month.
        You can easily navigate back and forth with the arrows
      </Typography>
    ),

    3: (
      <Typography variant="body2">
        Choose to only see actual sales (To Date) or to include the projected
        sales. When you choose the projected view you will see the actual sales
        when there is actual sales, and predicted sales for all dates or hours
        in the future. Look for the vertical line in the graphs to see where the
        actual sales stop and the projected begins
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        In the quick overview you will see a summary of the most important
        numbers for the selected restaurants and time period. Hover over each of
        them to see an explanation of the number and how we calculate it
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        This graph shows the employee cost. In the individual view you’ll see
        both sales and employee cost. This is to better understand how the vary
        in relation to each other
      </Typography>
    ),
    6: (
      <Typography variant="body2">
        Sick cost is the compensation paid to the employees during sick leave.
      </Typography>
    ),
    7: (
      <Typography variant="body2">
        This graph shows employee cost in terms of percent of sales without VAT,
        and is a crucial number for most restaurants. This is a way to measure
        how well the restaurant managers plan their staff in relation to the
        sales.
      </Typography>
    ),
    8: (
      <Typography variant="body2">
        The employee productivity shows how much sales each employee generates
        per hour. This is a key number for understanding how productive your
        staff is, or how well the staff planning is being done.
      </Typography>
    ),
  };

  //tutorial guide

  const downloadOptions = [
    () =>
      downloadSuperDashboardOne(
        staffData,
        employeeProductivity,
        // intervalValue,
        absoluteStaffSalesData,
        // deliveryCost,
        selectedRestaurant,
        statStaffData,
        averageEmployeeProductivity,
        averageEmployeeCost,
        averageSickCost
      ),
    // Add other function calls as necessary
  ];

  const handlePageChange = (newValue) => {
    setSelectedPage(newValue);
    setSelectedRestaurant([]);
    setEmployeeProductivity([]);
    setAverageEmployeeProductivity([]);
    setStaffData([]);
    setSalesData([]);
    setAbsoluteStaffSalesData([]);
    setSickCostData([]);
    setStatStaffData([]);
    setAverageEmployeeCost([]);
    setAverageSickCost([]);
    if (!newValue) return;
  };

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });
      setDefaultRestaurants(filteredRestaurants);

      if (primaryRestaurant) {
        const mainRestaurant = filteredRestaurants.filter(
          (restaurant) => restaurant.id === primaryRestaurant
        );
        setSelectedRestaurant([mainRestaurant[0]?.name]);
      }
    }
  }, [primaryRestaurant, setSelectedRestaurant, company]);

  const adjustDates = (date, dataRange, direction) => {
    let newDate = new Date(date);
    const adjustDay = direction === "forward" ? 1 : -1;
    const adjustWeek = direction === "forward" ? 7 : -7;
    const adjustMonth = direction === "forward" ? 1 : -1;

    switch (dataRange) {
      case "today":
        newDate.setDate(newDate.getDate() + adjustDay);
        break;
      case "thisweek":
        newDate.setDate(newDate.getDate() + adjustWeek);
        break;
      case "thismonth":
        newDate.setMonth(newDate.getMonth() + adjustMonth);
        break;
      default:
        break;
    }

    return newDate.toISOString().split("T")[0];
  };

  const handleBackwardClick = () => {
    setStatStaffData([]);
    setAverageEmployeeProductivity([]);
    setAverageEmployeeCost([]);
    setAverageSickCost([]);
    const newStartDate = adjustDates(startDate, dataRange, "backward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "backward"));
    }
  };

  const handleForwardClick = () => {
    setStatStaffData([]);
    setAverageEmployeeProductivity([]);
    setAverageEmployeeCost([]);
    setAverageSickCost([]);
    const newStartDate = adjustDates(startDate, dataRange, "forward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "forward"));
    }
  };

  const buildFetchUrl = (baseURL, options) => {
    let {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      // dataRange,
      // hasPredictionData,
      newValue,
    } = options;

    let startingDate = startDate;
    let endingDate = endDate;

    // Formatting startDate and endDate
    let formattedStartDate = startingDate;
    let formattedEndDate = endingDate;

    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }

    // Constructing URL with query parameters
    let url = baseURL;
    let isFirstQueryParam = true;

    // Helper function to append parameters
    const appendParam = (key, value) => {
      if (isFirstQueryParam) {
        url += `?${key}=${encodeURIComponent(value)}`;
        isFirstQueryParam = false;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // Append company if present
    if (company) {
      appendParam("company", company);
    }

    // Append start_date and end_date if present
    if (formattedStartDate && formattedEndDate) {
      appendParam("start_date", startDate);
      appendParam("end_date", endDate);
    }

    // Append restaurantNames if present
    if (selectedRestaurant.length > 0) {
      appendParam("restaurants", JSON.stringify(selectedRestaurant));
    }

    // Append newValue if present
    if (newValue) {
      appendParam("period", newValue);
    }

    // Log the formatted URL
    // console.log(url, "format url in function");

    // Return the URL as a string
    return url;
  };

  // const getAverageProductivity = useCallback(() => {
  //   // const interval = intervalValue;
  //   const urlOptions = {
  //     startDate,
  //     endDate,
  //     company,
  //     selectedRestaurant,
  //     dataRange,
  //   };

  //   let Url = buildFetchUrl(
  //     `${prefix}/api/employee_info/employee_productivity`,
  //     urlOptions
  //   );

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Token ${token}`,
  //   };

  //   const ranges = interval.split("-").map(Number);
  //   if (ranges) {
  //     Url += `&start_range=${ranges[0]}&end_range=${ranges[1]}`;
  //   }
  //   abortControllerRefForEmployeeProd.current.abort();
  //   abortControllerRefForEmployeeProd.current = new AbortController();
  //   setAvgEmpProdLoad(true);
  //   fetch(Url, {
  //     headers: headers,
  //     signal: abortControllerRefForEmployeeProd.current.signal,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.results) {
  //         const chartData = data.results
  //           .filter((entry) => entry.avg_productivity > 0)
  //           .map((entry) => {
  //             const { restaurant, avg_productivity, day_count } = entry;
  //             return {
  //               day_count: day_count,
  //               Color: getRandomColor(),
  //               id: restaurant,
  //               value: parseFloat(avg_productivity.toFixed(0)),
  //             };
  //           });

  //         // Calculate average
  //         const totalProductivity = chartData.reduce(
  //           (acc, curr) => acc + curr.value,
  //           0
  //         );
  //         const avgProductivityValue = totalProductivity / chartData.length;

  //         // Set the average to state
  //         setAverageEmployeeProductivity(avgProductivityValue.toFixed(0));

  //         setEmployeeProductivity(chartData);
  //       } else {
  //         setAverageEmployeeProductivity(null);
  //         setEmployeeProductivity([]);
  //       }
  //       setAvgEmpProdLoad(false);
  //     })
  //     .catch((error) => {
  //       // console.error("Error fetching sales data:", error);
  //       // setAvgEmpProdLoad(false);
  //       if (error.name !== "AbortError") {
  //         console.error(error);
  //         setAvgEmpProdLoad(false);
  //       }
  //     });
  // }, [
  //   startDate,
  //   company,
  //   endDate,
  //   intervalValue,
  //   selectedRestaurant,
  //   dataRange,
  // ]);

  // const getEmployeeProductivity = useCallback(() => {
  //   const urlOptions = {
  //     startDate,
  //     endDate,
  //     company,
  //     selectedRestaurant,
  //     dataRange,
  //     range,
  //   };
  //   const Url = buildFetchUrl(
  //     `${prefix}/api/employee_info/employee_productivity_per_day`,
  //     urlOptions
  //   );
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Token ${token}`,
  //   };
  //   setAvgEmpProdLoad(true);

  //   fetch(Url, { headers: headers })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.results) {
  //         const absoluteEmployeeProductivity = data.results.map((entry) => {
  //           const { period, avg_productivity } = entry;
  //           return {
  //             id: period,
  //             value: parseFloat(avg_productivity.toFixed(0)), // Use the percentage with 2 decimal points as 'value'
  //           };
  //         });
  //         const totalProductivity = absoluteEmployeeProductivity?.reduce(
  //           (acc, curr) => acc + curr.value,
  //           0
  //         );
  //         const avgProductivity =
  //           totalProductivity / absoluteEmployeeProductivity.length;

  //         // Set state for the average
  //         setAverageEmployeeProductivity(avgProductivity.toFixed(0));
  //         // setAvgEmpProdLoad(false);
  //       } else {
  //         setAverageEmployeeProductivity([]);
  //         setAvgEmpProdLoad(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching staff data:", error);
  //       setAvgEmpProdLoad(false);
  //     });
  // }, [endDate, selectedRestaurant, startDate, range, dataRange, company]);
  //API for staff data
  // const getStaffData = useCallback(() => {
  //   let newValue = range;
  //   const urlOptions = {
  //     startDate,
  //     endDate,
  //     company,
  //     selectedRestaurant,
  //     dataRange,
  //     range,
  //   };

  //   let url_to_fetch =
  //     // dataRange === "today"
  //     // ? `${prefix}/api/salesdata_hourly/employee_count`
  //     `${prefix}/api/salesdata_hourly/employee_hours`;
  //   const Url = buildFetchUrl(url_to_fetch, urlOptions);
  //   // Specific URL for fetching employee cost data
  //   // let employeeCostUrl = `${prefix}/api/salesdata_hourly/employee_hours`;
  //   // const employeeCostUrlFull = buildFetchUrl(employeeCostUrl, urlOptions);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Token ${token}`,
  //   };

  //   // let total_percentage = 0; // Variable to store the sum of all percentages
  //   // let totalEmployeeCost = 0;
  //   // let totalNetSales = 0;

  //   setStaffCostLoad(true);
  //   abortControllerRefForStaffData.current.abort();
  //   abortControllerRefForStaffData.current = new AbortController();
  //   // Fetching employee cost data separately

  //   // function processStaffSalesData(data, newValue) {
  //   //   // console.log(dataRange, "heray");

  //   //   const groupedByDate = data.reduce((acc, entry) => {
  //   //     const { employee_cost, employee_count, date } = entry;

  //   //     // console.log("Employee Count:", employee_count); // Check what employee_count is outputting

  //   //     let date_period = formatDate(newValue, date);
  //   //     if (!acc[date_period]) {
  //   //       acc[date_period] = {
  //   //         x: new Date(date_period),
  //   //         y: 0, // Initialize with zero
  //   //       };
  //   //     }

  //   //     if (dataRange === "today") {
  //   //       acc[date_period].y += employee_count;
  //   //       console.log("acume", employee_count); // Check what employee_count is outputting
  //   //     } else {
  //   //       acc[date_period].y += employee_cost; // Accumulate employee_cost
  //   //     }

  //   //     return acc;
  //   //   }, {});

  //   //   return Object.values(groupedByDate);
  //   // }
  //   function processStaffSalesData(data, newValue) {
  //     const groupedByDate = data.reduce((acc, entry) => {
  //       const { employee_cost, date } = entry;
  //       let date_period = formatDate(newValue, date);
  //       if (!acc[date_period]) {
  //         acc[date_period] = {
  //           x: new Date(date_period),
  //           y: 0,
  //         };
  //       }
  //       acc[date_period].y += parseFloat(employee_cost);
  //       return acc;
  //     }, {});

  //     return Object.values(groupedByDate); // Convert the accumulated object into an array of values
  //   }

  //   fetch(Url, {
  //     headers: headers,
  //     signal: abortControllerRefForStaffData.current.signal,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.message) {
  //         console.log(data, "final");
  //         const employeeCostData = processStaffSalesData(
  //           hasPredictionData
  //             ? data.message.prediction
  //             : data.message.historical,
  //           newValue
  //         );

  //         // Calculate total employee cost from the processed data
  //         const totalEmployeeCost = employeeCostData.reduce(
  //           (acc, entry) => acc + parseFloat(entry.y),
  //           0
  //         );
  //         setAverageEmployeeCost(totalEmployeeCost.toFixed(0));

  //         const resultsToUse = hasPredictionData
  //           ? data.message.prediction
  //           : data.message.historical;

  //         const historicalData = processStaffSalesData(
  //           data.message.historical,
  //           newValue
  //         );
  //         const predictionData = processStaffSalesData(
  //           data.message.prediction,
  //           newValue
  //         );
  //         console.log(historicalData, "histo");

  //         const absoluteStaffSalesData = [
  //           { id: "actual", data: historicalData },
  //           { id: "prediction", data: predictionData },
  //         ];
  //         setAbsoluteStaffSalesData(absoluteStaffSalesData);

  //         let totalSickCost = 0;
  //         const sickCostData = {
  //           ...resultsToUse.reduce((result, entry) => {
  //             const { sick_cost, date, restaurant, company } = entry;
  //             let date_period = formatDate(newValue, date);

  //             if (sick_cost !== null) {
  //               totalSickCost += parseFloat(sick_cost); // Add each sick_cost to the total
  //             }

  //             if (selectedRestaurant.length > 0) {
  //               return {
  //                 ...result,
  //                 [restaurant]: {
  //                   id: restaurant,
  //                   data: [
  //                     ...(result[restaurant]?.data || []),
  //                     {
  //                       x: date_period,
  //                       y: sick_cost !== null ? parseFloat(sick_cost) : 0,
  //                     },
  //                   ],
  //                 },
  //               };
  //             } else {
  //               return {
  //                 ...result,
  //                 [company]: {
  //                   id: company,
  //                   data: [
  //                     ...(result[company]?.data || []),
  //                     {
  //                       x: date_period,
  //                       y: sick_cost !== null ? parseFloat(sick_cost) : 0,
  //                     },
  //                   ],
  //                 },
  //               };
  //             }
  //           }, {}),
  //         };
  //         // console.log(sickCostData, "sick");
  //         setSickCostData(
  //           totalSickCost === null || totalSickCost < 0 || isNaN(totalSickCost)
  //             ? []
  //             : sickCostData
  //         );
  //         console.log(sickCostData, "final");
  //         setAverageSickCost(
  //           totalSickCost === null || totalSickCost < 0 || isNaN(totalSickCost)
  //             ? []
  //             : totalSickCost
  //         );
  //       } else {
  //         setStaffData([]);
  //         // setStatStaffData([]);
  //         // setSalesData([]);
  //         setAverageEmployeeCost(null);
  //         setAverageSickCost(null);
  //         setAbsoluteStaffSalesData([]);
  //         setSickCostData([]);
  //       }
  //       setStaffCostLoad(false);
  //     })
  //     .catch((error) => {
  //       // setStaffCostLoad(false);
  //       // console.error("Error fetching sales data:", error);
  //       if (error.name !== "AbortError") {
  //         console.error(error);
  //         setStaffCostLoad(false);
  //       }
  //     });
  // }, [
  //   startDate,
  //   endDate,
  //   range,
  //   selectedRestaurant,
  //   dataRange,
  //   hasPredictionData,
  //   company,
  // ]);
  function isBefore(date1, date2) {
    const dateOnly1 = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const dateOnly2 = new Date(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );
    return dateOnly1 < dateOnly2;
  }
  const getStaffData = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };

    let url_to_fetch =
      dataRange === "today"
        ? `${prefix}/api/salesdata_hourly/hourly_employee_hours`
        : `${prefix}/api/salesdata_hourly/employee_hours`;
    const Url = buildFetchUrl(url_to_fetch, urlOptions);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    let totalEmployeeCost = { historical: 0, prediction: 0 };
    let totalSickCost = { historical: 0, prediction: 0 };
    let totalNetSales = { historical: 0, prediction: 0 };

    setStaffCostLoad(true);
    abortControllerRefForStaffData.current.abort();
    abortControllerRefForStaffData.current = new AbortController();

    function processStaffSalesData(data, type) {
      const groupedByDate = data.reduce((acc, entry) => {
        const { employee_cost, total_net, date } = entry;
        let date_period = formatDate(range, date);
        if (!acc[date_period]) {
          acc[date_period] = {
            x: new Date(date_period),
            y: 0,
          };
        }
        acc[date_period].y += parseFloat(employee_cost);
        totalEmployeeCost[type] += parseFloat(employee_cost) || 0;
        totalNetSales[type] += parseFloat(total_net) || 0;
        return acc;
      }, {});

      return Object.values(groupedByDate); // Convert the accumulated object into an array of values
    }

    fetch(Url, {
      headers: headers,
      signal: abortControllerRefForStaffData.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const historicalData = processStaffSalesData(
            data.message.historical,
            "historical"
          );

          const Latest = new Date(latest_created_at);
          const dateEnd = new Date(endDate);
          let predictionData;

          if (isBefore(dateEnd, Latest)) {
            predictionData = processStaffSalesData(
              data.message.actual_prediction,
              "prediction"
            );
          } else {
            predictionData = processStaffSalesData(
              data.message.prediction,
              "prediction"
            );
          }

          const resultsToUse = hasPredictionData
            ? data.message.prediction
            : data.message.historical;

          const combinedStaffData = {};
          ["historical", "prediction"].forEach((type) => {
            const dataSet =
              type === "historical" ? historicalData : predictionData;
            dataSet.forEach((item) => {
              const key = item.id || "Sales";
              if (!combinedStaffData[key]) {
                combinedStaffData[key] = {
                  id: key,
                  historical: [],
                  prediction: [],
                };
              }
              combinedStaffData[key][type].push(item);
            });
          });

          const chartData = Object.keys(combinedStaffData)?.reduce(
            (result, key) => {
              const { historical, prediction } = combinedStaffData[key];
              if (historical.length) {
                result.push({ id: "actual", data: historical });
              }
              if (hasPredictionData && prediction.length) {
                result.push({ id: "prediction", data: prediction });
              }
              return result;
            },
            []
          );

          setAbsoluteStaffSalesData(chartData);

          setAverageEmployeeCost([
            totalEmployeeCost.historical.toFixed(0),
            hasPredictionData ? totalEmployeeCost.prediction.toFixed(0) : 0,
          ]);

          const staffData = resultsToUse.reduce((result, entry) => {
            const { employee_cost, total_net, date, restaurant, company } =
              entry;
            let date_period = formatDate(range, date);

            const percent_emp_cost =
              total_net > 0
                ? ((employee_cost / parseInt(total_net)) * 100).toFixed(1)
                : 0;

            const key = selectedRestaurant.length > 0 ? restaurant : company;
            if (!result[key]) {
              result[key] = { id: key, data: [] };
            }
            result[key].data.push({
              x: date_period,
              y: parseFloat(percent_emp_cost),
            });
            return result;
          }, {});

          setSalesData(() => ({
            ...resultsToUse.reduce((result, entry) => {
              const { total_net, date, restaurant } = entry;
              let date_period = formatDate(range, date);

              const key = selectedRestaurant.length > 0 ? restaurant : company;
              if (!result[key]) {
                result[key] = {
                  id: key,
                  Color: "#70335D",
                  data: [],
                };
              }
              result[key].data.push({
                x: new Date(date_period),
                y: parseFloat(total_net),
              });
              return result;
            }, {}),
          }));

          setStaffData(() => staffData);

          const avgStaffCostPercentage =
            totalNetSales.historical > 0
              ? (totalEmployeeCost.historical / totalNetSales.historical) * 100
              : 0;

          const avgStaffCostPrediction =
            hasPredictionData && totalNetSales.prediction > 0
              ? (totalEmployeeCost.prediction / totalNetSales.prediction) * 100
              : 0;

          setStatStaffData([
            avgStaffCostPercentage.toFixed(1),
            avgStaffCostPrediction.toFixed(1),
          ]);

          const sickCostData = resultsToUse.reduce((result, entry) => {
            const { sick_cost, date, restaurant, company } = entry;
            let date_period = formatDate(range, date);

            if (sick_cost !== null) {
              totalSickCost.historical += parseFloat(sick_cost);
            }

            const newData = {
              x: date_period,
              y: sick_cost !== null ? parseFloat(sick_cost) : 0,
            };

            const key = selectedRestaurant.length > 0 ? restaurant : company;
            if (!result[key]) {
              result[key] = { id: key, data: [] };
            }
            result[key].data.push(newData);
            return result;
          }, {});

          setSickCostData(
            totalSickCost.historical === null ||
              totalSickCost.historical < 0 ||
              isNaN(totalSickCost.historical)
              ? []
              : sickCostData
          );

          const averageSickCost = hasPredictionData
            ? [
                totalSickCost.historical.toFixed(0),
                totalSickCost.prediction.toFixed(0),
              ]
            : [totalSickCost.historical.toFixed(0), 0];

          setAverageSickCost(averageSickCost);
        } else {
          setStaffData([]);
          setStatStaffData([]);
          setSalesData([]);
          setAverageEmployeeCost([0, 0]);
          setAverageSickCost([0, 0]);
          setAbsoluteStaffSalesData([]);
          setSickCostData([]);
        }
        setStaffCostLoad(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setStaffCostLoad(false);
        }
      });
  }, [
    startDate,
    endDate,
    range,
    selectedRestaurant,
    dataRange,
    hasPredictionData,
    company,
    latest_created_at,
  ]);

  const formatDate = (newValue, date) => {
    let date_period;
    if (newValue === "week") {
      let [year, week] = date.split(" ");
      let actualYear = parseInt(year, 10);
      const firstJan = new Date(Date.UTC(actualYear, 0, 1)); // January 1st of the given year

      // Calculate the first Monday of the year
      const dayOffset = (1 - firstJan.getUTCDay() + 7) % 7;
      const firstMonday = new Date(firstJan);
      firstMonday.setDate(firstJan.getDate() + dayOffset);

      const weekOffset = (week - 1) * 7;
      date_period = new Date(firstMonday);
      date_period.setUTCDate(firstMonday.getUTCDate() + weekOffset);
    } else if (newValue === "month") {
      const [year, month] = date.split(" ");
      date_period = new Date(year, parseInt(month - 1), 23); // Set to the last day of the given month
    } else if (newValue === "hour") {
      const temp_date = new Date(date);
      const year = temp_date.getFullYear();
      const month = temp_date.getMonth() + 1; // JavaScript months are 0-indexed
      const day = temp_date.getDate();
      const hours = temp_date.getHours();
      const minutes = temp_date.getMinutes();

      const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}`;

      date_period = `${formattedDate} ${formattedTime}`;
    } else {
      date_period = new Date(date);
    }
    return date_period;
  };

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
      setStaffData([]);
      setAbsoluteStaffSalesData([]);
      setStatStaffData([]);
      setSickCostData([]);
      setAverageEmployeeCost([]);
      setAverageSickCost([]);
      setEmployeeProductivity([]);
    } else {
      getStaffData();
      // getAbsoluteStaffData();
      // getSickCostData();
    }
  }, [
    getStaffData,
    // getAbsoluteStaffData,
    // getSickCostData,
    selectedRestaurant,
    selectedPage,
  ]);

  // useEffect(() => {
  //   if (selectedPage === "multiple" && selectedRestaurant.length === 0) {
  //     setAverageEmployeeProductivity([]);
  //     setEmployeeProductivity([]);
  //   } else {
  //     getAverageProductivity();
  //   }
  // }, [getAverageProductivity, selectedRestaurant, selectedPage]);

  // useEffect(() => {
  //   if (selectedPage === "company") {
  //     setSelectedRestaurant([]);
  //   }
  // }, [setSelectedRestaurant, selectedPage]);

  useEffect(() => {
    return () => {
      // if (abortControllerRefForEmployeeProd.current) {
      //   abortControllerRefForEmployeeProd.current.abort();
      // }

      if (abortControllerRefForStaffData.current) {
        abortControllerRefForStaffData.current.abort();
      }
      // if (abortControllerRefForEmployeeCount.current) {
      //   abortControllerRefForEmployeeCount.current.abort();
      // }
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1300, xl: 1600 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "1" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 1, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "10%", xs: "20%" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 1}
            >
              <Box
                sx={{
                  ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: { lg: "row", xs: "column" },
                  alignItems: "center",
                  mt: { lg: 0, xs: 1 },
                  gap: { lg: 0, xs: 1 },
                }}
              >
                {/* {selectedPage === "multiple" ? ( */}
                <FormControl sx={{ width: 200, m: 0 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    {selectedPage === "company" ? company : "All Restaurants"}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedPage === "company" ? [] : selectedRestaurant}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        label={
                          selectedPage === "company"
                            ? company
                            : "All Restaurants"
                        }
                      />
                    }
                    // renderValue={(selected) => selected.join(", ")}
                    renderValue={(selected) =>
                      selected
                        .map((name) => getRestaurantNameById(name))
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                    disabled={selectedPage === "company"}
                    sx={{
                      "& .MuiSelect-select": {
                        // height: '3rem',
                        padding: "0",
                        textIndent: "0.25em",
                        textAlign: "center",
                      },
                      background: "#F8F8F8",
                      mx: ".5rem",
                      height: "3rem",
                      color: "black",
                      boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    }}
                  >
                    {/* {restaurants.map((restaurant) => (
                        <MenuItem key={restaurant.name} value={restaurant.name}>
                          <Checkbox
                            checked={
                              restaurantNames.indexOf(restaurant.name) > -1
                            }
                          />
                          <ListItemText primary={restaurant.name} />
                        </MenuItem>
                        ))} */}

                    {defaultRestaurants.map((restaurant) => (
                      <MenuItem key={restaurant.id} value={restaurant.name}>
                        <Checkbox
                          checked={selectedRestaurant.includes(restaurant.name)}
                        />
                        <ListItemText primary={restaurant.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <ToggleButtonGroup
                    value={selectedPage}
                    exclusive
                    onChange={(event) => {
                      handlePageChange(event.target.value);
                      setSelectedRestaurant([]);
                      setEmployeeProductivity([]);
                      setAverageEmployeeProductivity([]);
                      // setSelectedPage([]);
                      setStaffData([]);
                      setSalesData([]);
                      setAbsoluteStaffSalesData([]);
                      setSickCostData([]);
                      setStatStaffData([]);
                      setAverageEmployeeCost([]);
                      setAverageSickCost([]);
                    }}
                    sx={{
                      "& .MuiToggleButton-root": {
                        // height: "3rem",
                        background: "#F8F8F8",
                        "&.Mui-selected": {
                          background: "#D6D6D6", // Darker shade for the selected button
                        },
                      },
                      mx: ".5rem",
                    }}
                    aria-label="Range"
                  >
                    <ToggleButton
                      value="company"
                      aria-label="company"
                      disabled={!isAdmin}
                    >
                      Company
                    </ToggleButton>
                    <ToggleButton value="multiple" aria-label="multiple">
                      Restaurants
                    </ToggleButton>
                    {/* <ToggleButton value="individual" aria-label="individual">
                      Individual
                    </ToggleButton> */}
                  </ToggleButtonGroup>
                </FormControl>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 2}
            >
              <Box
                sx={{
                  ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mt: { lg: 3 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "solid 1px red",
                    height: "100%",
                    width: { xs: "80%", lg: "100%" },
                  }}
                >
                  <ArrowBackIcon
                    onClick={handleBackwardClick}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size

                      cursor: "pointer", // Ensures the cursor is a pointer // Ensures the cursor is a pointer
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                  <FormControl sx={{ width: { lg: 250, xs: 300 }, m: 0 }}>
                    {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                    <ToggleButtonGroup
                      value={dataRange}
                      // The range is the same. Just hour for today.
                      //  Otherwise it's just the startdate and enddate that need to be adjusted accordingly
                      disabled={staffCostLoad}
                      exclusive
                      onChange={(e) => {
                        setEmployeeProductivity([]);
                        setAverageEmployeeProductivity([]);
                        setStaffData([]);
                        setSalesData([]);
                        setAbsoluteStaffSalesData([]);
                        setSickCostData([]);
                        setStatStaffData([]);
                        setAverageEmployeeCost([]);
                        setAverageSickCost([]);
                        setDataRange(e.target.value);
                        let startingDate;
                        let endingDate;
                        switch (e.target.value) {
                          case "today":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate()
                              )
                            );
                            break;
                          case "thisweek":
                            // Find the start of the week (Monday)
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate() -
                                  now.getUTCDay() +
                                  (now.getUTCDay() === 0 ? -6 : 1)
                              )
                            );
                            // Find the end of the week (Sunday)
                            endingDate = new Date(startingDate);
                            endingDate.setDate(startingDate.getDate() + 6); // Add 6 days to get to Sunday
                            break;

                          case "thismonth":
                            startingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                1
                              )
                            );
                            endingDate = new Date(
                              Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth() + 1,
                                0
                              ) // Set to the last day of the current month
                            );
                            break;
                          default:
                            // Handle any other cases or default behavior
                            break;
                        }
                        setStartDate(startingDate?.toISOString().split("T")[0]);
                        setEndDate(endingDate?.toISOString().split("T")[0]);
                      }}
                      sx={{
                        "& .MuiToggleButton-root": {
                          height: "3rem",
                          background: "#F8F8F8",
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                      }}
                      aria-label="Range"
                    >
                      <ToggleButton value="today" aria-label="Today">
                        Day
                      </ToggleButton>
                      <ToggleButton value="thisweek" aria-label="This Week">
                        Week
                      </ToggleButton>
                      <ToggleButton value="thismonth" aria-label="This Month">
                        Month
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                  <ArrowForwardIcon
                    onClick={handleForwardClick}
                    sx={{
                      padding: "8px", // Adds space around the icon, increasing the border size

                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                        borderRadius: "50%", // Makes the background round
                      },
                    }}
                  />
                </Box>
                <FormatDesiredDate
                  startDate={startDate}
                  endDate={endDate}
                  dataRange={dataRange}
                />
              </Box>
            </Tooltip>

            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 3}
            >
              <Tooltip
                title="Show only numbers back in time or include predicted
                  numbers as well"
                arrow
                placement="bottom"
              >
                <Box
                  sx={{
                    ...(counter === 3 && helpMode ? getHighlightedStyle() : {}),

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    }}
                  >
                    To Date
                  </Typography>
                  <Switch
                    checked={hasPredictionData}
                    onChange={handlePredictionData}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                    }}
                  >
                    Projected
                  </Typography>
                </Box>
              </Tooltip>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isXSScreen && <LastUpdate />}
            </Box>
            <Box
              sx={{
                marginBottom: { xs: "1rem", lg: 0 }, // Adds bottom margin on xs screens
              }}
            >
              <SettingsDialog
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                range={range}
                downloadOptions={downloadOptions}
                company={company}
                setCompany={setCompany}
                email={email}
              />
              <HelpModeButton
                isXSScreen={isXSScreen}
                handleHelpMode={handleHelpMode}
                helpMode={helpMode}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "45%", xs: "150vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 4}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 4 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "30%", xs: "90%" },
                  // gap: { lg: 2, xs: 3 },
                  // justifyContent: "space-evenly",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 4 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "20rem" },
                  fontFamily: "Montserrat",
                  // paddingY: { lg: 0, xs: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "20%",
                    width: "100%",
                    // outline: "solid 1px red",
                  }}
                >
                  <TitleStyle tooltipKey="key_metrics">Key Metrics</TitleStyle>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "80%",
                    width: "100%",
                    // outline: "solid 1px red",
                    overflowY: "auto",
                    borderRadius: "0 0 1rem 1rem",
                  }}
                >
                  <YipsOverviewTable
                    statStaffData={statStaffData}
                    averageEmployeeCost={averageEmployeeCost}
                    averageEmployeeProductivity={averageEmployeeProductivity}
                    averageSickCost={averageSickCost}
                    staffCostLoad={staffCostLoad}
                    // avgEmpProdLoad={avgEmpProdLoad}
                  />
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 5}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "45%", xs: "90%" },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 5 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                }}
              >
                <Box>
                  <TitleStyle tooltipKey="employee_cost">
                    Employee Cost
                  </TitleStyle>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "80%",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {staffCostLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : dataRange === "today" ? (
                    <PointHourlyLineChart
                      data={absoluteStaffSalesData}
                      isMultiRestaurant={true}
                      isNotPercentage={true}
                      hasPredictionLine={true}
                      range={range}
                      startDate={startDate}
                      endDate={endDate}
                      isXSScreen={isXSScreen}
                      isMinimal={true}
                      isCurrency={true}
                      isEmployeeChart={true}
                      // useKpiFormat={true}
                      employeeCount={true}
                      isOverlapChart={true}
                    />
                  ) : (
                    <LineChart
                      data={absoluteStaffSalesData}
                      isNotPercentage={true}
                      hasPredictionLine={true}
                      range={range}
                      startDate={startDate}
                      endDate={endDate}
                      isXSScreen={isXSScreen}
                      isMinimal={true}
                      isCurrency={true}
                      hasStaffMarker={true}
                      // useKpiFormat={true}
                      isOverlapChart={true}
                    />
                  )}
                </Box>
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 6}
              placement={isXSScreen ? "top" : "top"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 6 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "35%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 6 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                }}
              >
                <Box
                // sx={{
                //   display: "flex",
                //   height: "10%",
                //   width: "100%",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   flexDirection: "column",
                // }}
                >
                  <TitleStyle tooltipKey="sick_cost">Sick Cost</TitleStyle>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "80%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    // border: "1px solid",
                  }}
                >
                  {staffCostLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                        // border: "1px solid",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : (
                    <LineChart
                      data={sickCostData}
                      isNotPercentage={true}
                      range={range}
                      startDate={startDate}
                      endDate={endDate}
                      isXSScreen={isXSScreen}
                      isSickCost={true}
                      isCurrency={true}
                      useKpiFormat={true}
                    />
                  )}
                </Box>
              </Box>
            </Tooltip>
          </Box>

          {/* First section ends here */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: { lg: "flex-start", xs: "center" },
              height: { lg: "60%", xs: "120vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 7}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  ...(counter === 7 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "100%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 7 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "90%", xs: "30rem" },
                }}
              >
                <Box>
                  <TitleStyle tooltipKey="staff_percent">
                    Staff Cost Percentage
                  </TitleStyle>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    // border: "solid red 1px",
                  }}
                >
                  {staffCostLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "50%",
                        // border: "solid red 1px",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        // mr: "10%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                        }}
                      >
                        {dataRange === "today" ? (
                          <HourlySlicesLineChart
                            data={staffData}
                            isDetailedChart={false}
                            isStaffChart={true}
                            isXSScreen={isXSScreen}
                            isDailyData={true}
                            isSecondaryChart={false}
                          />
                        ) : (
                          <SlicesLineChart
                            data={staffData}
                            isDetailedChart={false}
                            isStaffChart={true}
                            isXSScreen={isXSScreen}
                            hasStaffMarker={true}
                          />
                        )}
                      </Box>
                      {(selectedPage === "company" ||
                        (selectedPage === "multiple" &&
                          selectedRestaurant.length <= 1)) && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                          }}
                        >
                          {dataRange === "today" ? (
                            <HourlySlicesLineChart
                              data={salesData}
                              isDetailedChart={false}
                              isStaffChart={true}
                              isNotPercentage={true}
                              isSecondaryChart={true}
                              secondaryData={staffData}
                              isXSScreen={isXSScreen}
                              hasPredictionLine={true}
                              isDailyData={true}
                            />
                          ) : (
                            <SlicesLineChart
                              data={salesData}
                              isDetailedChart={false}
                              isStaffChart={true}
                              isNotPercentage={true}
                              isSecondaryChart={true}
                              secondaryData={staffData}
                              isXSScreen={isXSScreen}
                              hasPredictionLine={true}
                              hasStaffMarker={true}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Tooltip>

            {/* Page ends here */}
          </Box>

          <Dialog
            open={openHelpDialog}
            onClose={() => setOpenHelpDialog(false)}
            aria-labelledby="help-dialog-title"
            aria-describedby="help-dialog-description"
          >
            <DialogTitle id="help-dialog-title">
              {"Help Information"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="help-dialog-description">
                This help button is designed to provide you with additional
                information and guidance on how to use the features of this
                application. Clicking this button will display useful tips and
                FAQs to help you navigate and make the most of the app's
                functionalities.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenHelpDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <SnackbarAlert
            open={open}
            severity={severity}
            message={message}
            onClose={handleClose}
          />
          {helpMode && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: { lg: "180px", xs: "280px" },
                position: "fixed",
                bottom: 20,
                right: 20,
                padding: "10px",
                // border:"solid 1px red"
              }}
            >
              <NavigationButtons
                isXSScreen={isXSScreen}
                setHelpMode={setHelpMode}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                counter={counter}
                maxSteps={maxSteps}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default YipsNewSuperDashboard;
