import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import formatKpiNumber from "../../utils/formatKpiNumber";
import ChartLoading from "../../components/Loading/ChartLoading";

const YipsOverviewTable = ({
  TotalData = [],
  isFlatKPIVisible = true,
  profitLoad = false,
  staffLoad = false,
  deliveryCostLoad = false,
  averageEmployeeCost = null,
  averageEmployeeProductivity = null,
  totalActualTakeOut = null,
  averageSickCost = null,
  statStaffData = null,
  sickCostPercent = null,
  totalActualTakeOutPercentage = null,
  GrossProfitPercentage = null,
  GrossProfitIIPercentage = null,
  totalSalesStatGoal = null,
  totalGrossProfitStatGoal = null,
  totalNetProfitStatGoal = null,
  load = false,
  dailyStaffLoad = false,
  productivityLoad = false,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (
      !profitLoad &&
      !deliveryCostLoad &&
      !staffLoad &&
      !load &&
      !dailyStaffLoad &&
      !productivityLoad
    ) {
      timer = setTimeout(() => {
        setLoading(false);
      }, 4000);
    } else {
      setLoading(true);
    }

    return () => clearTimeout(timer);
  }, [
    profitLoad,
    deliveryCostLoad,
    staffLoad,
    load,
    dailyStaffLoad,
    productivityLoad,
  ]);

  if (
    profitLoad ||
    deliveryCostLoad ||
    staffLoad ||
    load ||
    dailyStaffLoad ||
    productivityLoad
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {loading ? <ChartLoading /> : <h2>No Data</h2>}
      </Box>
    );
  }

  //Styles
  const nameCellStyle = {
    py: 0.5,
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: "transparent",
    padding: {
      lg: "4px 6px",
      xs: "6px 8px",
    },
    fontSize: "0.875rem",
    overflow: "hidden",
  };

  const valueCellStyle = {
    py: 0.5,
    fontWeight: 700,
    fontFamily: "'Montserrat', sans-serif",
    backgroundColor: "transparent",
    padding: {
      lg: "4px 6px",
      xs: "6px 8px",
    },
    overflow: "hidden",
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const generateTooltipContent = (
    currentValue,
    goalValue,
    dataType,
    isPrediction
  ) => {
    const formatGoalText = (current, goal) => {
      const percentageReached = (current / goal) * 100;
      const predictionText = isPrediction ? "predicted total" : "actual total";
      return `Your ${predictionText} is ${percentageReached.toFixed(
        1
      )}% of the ${new Intl.NumberFormat("nb-NO", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(goal)} goal.`;
    };

    if (goalValue && !isNaN(goalValue) && goalValue !== 0) {
      return (
        <Typography sx={{ fontSize: "0.875rem" }}>
          {formatGoalText(currentValue, goalValue)}
        </Typography>
      );
    } else {
      switch (dataType) {
        case "sales":
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              The sales with MVA for the select restaurants and date period
            </Typography>
          );
        case "gross profit":
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              Gross profit I is sales without MVA minus the cost of goods
            </Typography>
          );
        case "net profit":
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              Gross Profit II is Gross Profit I minus employee cost and third
              party delivery cost
            </Typography>
          );
        default:
          return (
            <Typography sx={{ fontSize: "0.875rem" }}>
              Not Recognized
            </Typography>
          );
      }
    }
  };

  const compareValues = (currentValue, goalValue) => {
    if (profitLoad) {
      return null;
    }

    if (
      currentValue === null ||
      goalValue === null ||
      currentValue === 0 ||
      goalValue === 0 ||
      isNaN(currentValue) ||
      isNaN(goalValue)
    ) {
      return null;
    }

    if (currentValue >= goalValue) {
      return <KeyboardDoubleArrowUpIcon sx={{ color: "green" }} />;
    } else {
      return <KeyboardDoubleArrowDownIcon sx={{ color: "red" }} />;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        fontFamily: "'Montserrat', sans-serif",
        background: "#FFF",
        borderRadius: "0 0 1rem 1rem",
      }}
    >
      <TableContainer>
        <Table aria-label="KPI data table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#ededed",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                  overflow: "hidden",
                },
              }}
            >
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                  padding: "6px 8px",
                  fontSize: "0.875rem",
                }}
              >
                KPI
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                Actual
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  py: 0.5,
                }}
              >
                Prediction
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    The sales with MVA for the select restaurants and date
                    period
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Sales</TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.historical?.totalSales || 0,
                  totalSalesStatGoal,
                  "sales",
                  false
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={valueCellStyle}>
                  <Box sx={boxStyle}>
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.historical?.totalSales,
                          totalSalesStatGoal
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.historical?.totalSales) ||
                        " - "
                      : " - "}
                  </Box>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.prediction?.totalSales || 0,
                  totalSalesStatGoal,
                  "sales",
                  true
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={valueCellStyle}>
                  <Box sx={boxStyle}>
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.prediction?.totalSales,
                          totalSalesStatGoal
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(TotalData.prediction?.totalSales) ||
                        " - "
                      : " - "}
                  </Box>
                </TableCell>
              </Tooltip>
            </TableRow>
            {/* <TableRow
              sx={{
                backgroundColor: "#f7f7f7",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Gross Profit I is total sales minus cost of goods
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Gross Profit I</TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.historical?.totalGrossProfitI || 0,
                  totalGrossProfitStatGoal,
                  "gross profit",
                  false
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={valueCellStyle}>
                  <Box sx={boxStyle}>
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.historical?.totalGrossProfitI,
                          totalGrossProfitStatGoal
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(
                          TotalData.historical?.totalGrossProfitI
                        ) || " - "
                      : isNaN(GrossProfitPercentage?.[0])
                      ? " - "
                      : GrossProfitPercentage?.[0]}
                  </Box>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.prediction?.totalGrossProfitI || 0,
                  totalGrossProfitStatGoal,
                  "gross profit",
                  true
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={valueCellStyle}>
                  <Box sx={boxStyle}>
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.prediction?.totalGrossProfitI,
                          totalGrossProfitStatGoal
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(
                          TotalData.prediction?.totalGrossProfitI
                        ) || " - "
                      : isNaN(GrossProfitPercentage?.[1])
                      ? " - "
                      : GrossProfitPercentage?.[1]}
                  </Box>
                </TableCell>
              </Tooltip>
            </TableRow> */}
            {/* <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Gross Profit II is Gross Profit I minus employee cost and
                    third party delivery cost
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Gross Profit II</TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.historical?.totalGrossProfitII || 0,
                  totalNetProfitStatGoal,
                  "net profit",
                  false
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={valueCellStyle}>
                  <Box sx={boxStyle}>
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.historical?.totalGrossProfitII,
                          totalNetProfitStatGoal
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(
                          TotalData.historical?.totalGrossProfitII
                        ) || " - "
                      : isNaN(GrossProfitIIPercentage?.[0])
                      ? " - "
                      : GrossProfitIIPercentage?.[0]}
                  </Box>
                </TableCell>
              </Tooltip>
              <Tooltip
                title={generateTooltipContent(
                  TotalData.prediction?.totalGrossProfitII || 0,
                  totalNetProfitStatGoal,
                  "net profit",
                  true
                )}
                placement="top"
                arrow
              >
                <TableCell align="center" sx={valueCellStyle}>
                  <Box sx={boxStyle}>
                    {isFlatKPIVisible
                      ? compareValues(
                          TotalData.prediction?.totalGrossProfitII,
                          totalNetProfitStatGoal
                        )
                      : ""}
                    {isFlatKPIVisible
                      ? formatKpiNumber(
                          TotalData.prediction?.totalGrossProfitII
                        ) || " - "
                      : isNaN(GrossProfitIIPercentage?.[1])
                      ? " - "
                      : GrossProfitIIPercentage?.[1]}
                  </Box>
                </TableCell>
              </Tooltip>
            </TableRow> */}
            <TableRow
              sx={{
                backgroundColor: "#f7f7f7",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Employee cost is the breakdown of employee-related expenses
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Total Employee Cost</TableCell>
              </Tooltip>
              <TableCell align="center" sx={valueCellStyle}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageEmployeeCost?.[0]) || " - "
                  : statStaffData?.[0] || " - "}
              </TableCell>
              <TableCell align="center" sx={valueCellStyle}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageEmployeeCost?.[1]) || " - "
                  : statStaffData?.[1] || " - "}
              </TableCell>
            </TableRow>
            {/* <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Employee productivity shows how much sales each employee
                    generates per hour.
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Emp Productivity/hr</TableCell>
              </Tooltip>
              <TableCell align="center" sx={valueCellStyle}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageEmployeeProductivity) || " - "
                  : " - "}
              </TableCell>
              <TableCell align="center" sx={valueCellStyle}>
                N/A
              </TableCell>
            </TableRow> */}
            <TableRow
              sx={{
                backgroundColor: "white",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    The compensation paid to employees on leave
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>Total Sick Cost</TableCell>
              </Tooltip>
              <TableCell align="center" sx={valueCellStyle}>
                {isFlatKPIVisible
                  ? formatKpiNumber(averageSickCost?.[0]) || " - "
                  : sickCostPercent?.[0] || " - "}
              </TableCell>
              <TableCell align="center" sx={valueCellStyle}>
                N/A
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f7f7f7",
                "& > *": {
                  py: 0.5,
                  fontFamily: "'Montserrat', sans-serif",
                },
              }}
            >
              <Tooltip
                title={
                  <Typography>
                    Total amount of sales delivered through third party services
                  </Typography>
                }
                placement="top"
                arrow
              >
                <TableCell sx={nameCellStyle}>
                  Third Party Delivery Sales
                </TableCell>
              </Tooltip>
              <TableCell align="center" sx={valueCellStyle}>
                {isFlatKPIVisible
                  ? formatKpiNumber(totalActualTakeOut) || " - "
                  : totalActualTakeOutPercentage || " - "}
              </TableCell>
              <TableCell align="center" sx={valueCellStyle}>
                N/A
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default YipsOverviewTable;
