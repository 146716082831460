import React, {
  useState,
  useEffect,
  useCallback,
  // useMemo,
  useRef,
} from "react";
import {
  // Grid,
  //    Container,
  // Card,
  Typography,
  // TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardContent,
  Tooltip,
  useMediaQuery,
  // Collapse,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Switch,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SnackbarAlert from "../utils/SnackbarAlert";
import ChartLoading from "../components/Loading/ChartLoading";
import LineChart from "../components/LineCharts/LineChart";
import downloadProfitLoss from "../utils/ExcelDownloads/DownloadProfitLoss";
import LastUpdate from "../utils/LastUpdate";
import PointHourlyLineChart from "../components/LineCharts/PointHourlyLineChart";
import formatDate from "../utils/FormatDate";
// import KPILoad from "../components/Loading/KPILoad";
import getHighlightedStyle from "../utils/HighlightedStyle";
import NavigationButtons from "../utils/HelpButton";
import Prefix from "../utils/PrefixLink";
// import PieChart from "../components/PieChart";
// import ProdPieChart from "../components/ProdPieChart";
import ProductPieChart from "./DashboardComponents/ProductPieChart";
import SettingsDialog from "../components/SettingsDialog";
// import formatNumber from "../utils/FormatNumber";
import FormatDesiredDate from "../utils/FormatDesiredDate";
import GrossProfitLineChart from "../components/LineCharts/GrossProfitLineChart";
import GrossProfitHourlyLineChart from "../components/LineCharts/GrossProfitHourlyLineChart";
import OverviewTable from "./DashboardComponents/OverviewTable";
import getLineColor from "../utils/GetColor";
import BasicOverviewTable from "./DashboardComponents/BasicOverviewTable";
import TitleStyle from "../components/Style/Title";
import HelpModeButton from "../components/HelpModeButton";
// import StyledToggleButtonGroup from "./DashboardComponents/ToggleButtonStyle";

const role = JSON.parse(localStorage.getItem("user"))?.role;

const ITEM_HEIGHT = "1rem";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// const isEmpty = (obj) => {
//   return Object.keys(obj).length === 0 && obj.constructor === Object;
// };

// const comp = localStorage.getItem("company");
const token = localStorage.getItem("token");

const toISOStringWithoutTimezone = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(
    date.getTime() - offset * 60 * 1000 + 60 * 60 * 1000
  );

  return adjustedDate.toISOString();
};

const prefix = Prefix();

const NewProfitLoss = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dataRange,
  setDataRange,
  selectedPage,
  setSelectedPage,
  selectedRestaurant,
  setSelectedRestaurant = [],
  restaurant,
  hasPredictionData,
  setHasPredictionData,
  range,
  company,
  setCompany,
  productMixData,
}) => {
  const latest_created_at = localStorage.getItem("latest_records");

  const now = latest_created_at ? new Date(latest_created_at) : new Date();
  const user = JSON.parse(localStorage.getItem("user"));

  // const [company, setCompany] = useState(comp);
  // const primaryRestaurant = user?.primary_restaurant;
  const email = user?.email;

  const isAdmin = user?.company_admin === true;

  const [load, setLoad] = useState(false);
  const [staffData, setStaffData] = useState([]);
  // const [productMixData, setProductMixData] = useState([]);
  const [grossProfit, setGrossProfit] = useState([]);
  const [grossProfitPercent, setGrossProfitPercent] = useState([]);
  const [totals, setTotals] = useState([]);
  const [statStaffData, setStatStaffData] = useState([]);

  const [sickCostPercent, setSickCostPercent] = useState([]);
  const [averageNetProfitPercentage, setAverageNetProfitPercentage] = useState(
    []
  );
  const [averageGrossProfitPercentage, setAverageGrossProfitPercentage] =
    useState(null);
  // const [totalSalesStat, setTotalSalesStat] = useState(null);
  // const [totalGrossProfitStat, setTotalGrossProfitStat] = useState(null);
  // const [totalNetProfitStat, setTotalNetProfitStat] = useState(null);
  const [totalSalesStatGoal, setTotalSalesStatGoal] = useState(null);
  const [totalGrossProfitStatGoal, setTotalGrossProfitStatGoal] =
    useState(null);

  const [totalNetProfitStatGoal, setTotalNetProfitStatGoal] = useState(null);

  const [averageEmployeeProductivity, setAverageEmployeeProductivity] =
    useState(null);
  const [averageEmployeeCost, setAverageEmployeeCost] = useState([]);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [profitLoad, setProfitLoad] = useState(false);
  const [staffLoad, setStaffLoad] = useState(false);
  const [dailyStaffLoad, setDailyStaffLoad] = useState(false);
  const [deliveryCostLoad, setDeliveryCostLoad] = useState(false);
  const [productivityLoad, setProductivityLoad] = useState(false);
  const [averageSickCost, setAverageSickCost] = useState([]);

  const [totalActualTakeOut, setTotalActualTakeOut] = useState(null);
  const [totalActualTakeOutPercentage, setTotalActualTakeOutPercentage] =
    useState([]);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState(null);
  const theme = useTheme();

  const isXSScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPercentChartVisible, setIsPercentChartVisible] = useState(true);
  const [isFlatKPIVisible, setIsFlatKPIVisible] = useState(true);
  const [helpMode, setHelpMode] = useState(false);
  const [counter, setCounter] = useState(1);
  const maxSteps = 8;

  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [selectedDatasets, setSelectedDatasets] = useState(["Sales"]); // Initially set to 'All'

  // Abort controllers
  const abortControllerRefForGrossProfit = useRef(new AbortController());
  // const abortControllerRefForArticleData = useRef(new AbortController()); // Separate ref for article data fetches
  const abortControllerRefForStaffData = useRef(new AbortController()); // Separate ref for article data fetches
  // const abortControllerRefForArticleGroupData = useRef(new AbortController()); // Separate ref for article data fetches

  const getRestaurantNameById = (restaurantName) => {
    const restaurant = defaultRestaurants.find(
      (r) => r.name === restaurantName
    );
    return restaurant ? restaurant.name : "";
  };

  if (selectedPage === "individual") {
    setSelectedPage("multiple");
  }

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  // };

  const handlePredictionData = () => {
    setHasPredictionData((prev) => !prev);
  };

  // const handleOpenSettingsDialog = () => {
  //   setOpenDialog(true);
  // };
  const handleToggleChart = () => {
    setIsPercentChartVisible((prev) => !prev);

    if (
      isPercentChartVisible &&
      selectedDatasets.length === 1 &&
      selectedDatasets[0] === "Sales"
    ) {
      setSelectedDatasets(["Gross Profit II"]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Assuming downloadProfitLoss, downloadTrends, etc. are function definitions that you have imported.
  const downloadOptions = [
    () =>
      downloadProfitLoss(
        grossProfitPercent,
        grossProfit,
        staffData,
        selectedRestaurant,
        averageSickCost,
        totalActualTakeOut,
        averageEmployeeCost,
        averageEmployeeProductivity,
        totals,
        dataRange,
        filteredData,
        staffData,
        averageGrossProfitPercentage,
        averageNetProfitPercentage,
        statStaffData,
        sickCostPercent,
        totalActualTakeOutPercentage
      ),
    // Add other function calls as necessary
  ];
  // console.log(totals, "download total");
  // console.log(
  //   grossProfitPercent,
  //   grossProfit,
  //   productMixData,
  //   staffData,
  //   selectedRestaurant,

  //   averageEmployeeCost,
  //   averageEmployeeProductivity,
  //   "download chij"
  // );

  const handlePageChange = (newValue) => {
    if (newValue === "company") {
      newValue = "company";
    }
    setSelectedPage(newValue); // Update the state with the new value

    // if (!newValue) return; // If no button is selected, do nothing

    if (newValue === "multiple") {
    } else if (newValue === "company") {
      setSelectedRestaurant([]);
    }
  };
  const getTooltipContent = () => {
    return dataRange === "today"
      ? "Showing metrics for the selected day"
      : dataRange === "thisweek"
      ? "Showing metrics for the selected week"
      : dataRange === "thismonth"
      ? "Showing metrics for the selected month"
      : "Showing metrics for today";
  };
  const tooltipContent = {
    1: (
      <Typography variant="body2">
        You can filter the dashboards to see the whole company, individual
        restaurants or a combination of multiple restaurants
      </Typography>
    ),
    2: (
      <Typography variant="body2">
        Choose to filter the dashboard by a single day, week or a whole month.
        You can easily navigate back and forth with the arrows
      </Typography>
    ),
    3: (
      <Typography variant="body2">
        Choose to only see actual sales (To Date) or to include the projected
        sales. When you choose the projected view you will see the actual sales
        when there is actual sales, and predicted sales for all dates or hours
        in the future. Look for the vertical line in the graphs to see where the
        actual sales stop and the projected begins
      </Typography>
    ),
    4: (
      <Typography variant="body2">
        This card gives you the key numbers for the selected restaurants and
        time period. Click on the % NOK toggle to switch between percentage view
        and NOK view
      </Typography>
    ),
    5: (
      <Typography variant="body2">
        See your sales, gross and net profit for the past, present and future.
        Hover over the data points to see how each number was calculated
      </Typography>
    ),
    // 6: (
    //   <Typography variant="body2">
    //     This card tells you the most pressing deviations that you should be
    //     aware of. In this case, we can see four dates where the restaurant has a
    //     very high staff cost percentage.
    //   </Typography>
    // ),
    6: (
      <Typography variant="body2">
        The product sales graph shows you how much each product sells. Hover
        over the data points to see how many percent of sales each product group
        or product had
      </Typography>
    ),
    7: (
      <Typography variant="body2">
        The staff cost percentage shows the employee costs’ share of sales
        without VAT. this is a key number to measure how well managers plan
        their staff schedules. The horizontal line is the percentage goal set by
        the company
      </Typography>
    ),
    // ... add more steps as needed
  };

  //tutorial guide

  const handleHelpMode = () => {
    setHelpMode(!helpMode);
    setCounter(1); // Reset counter when toggling helpMode
  };

  const handleNext = () => {
    setCounter((prev) => {
      const newCounter = prev + 1;
      if (newCounter >= 8) {
        setHelpMode(false);
      } else {
        // Scroll logic for mobile view in helpMode, only after counter reaches 4
        if (isXSScreen && helpMode && newCounter > 4) {
          const remInPixels = parseFloat(
            getComputedStyle(document.documentElement).fontSize
          );
          window.scrollBy({
            top: 30 * remInPixels, // Scroll by 30rem in pixels
            behavior: "smooth",
          });
        }
      }
      return newCounter;
    });
  };

  const handlePrevious = () => {
    setCounter((prev) => {
      const newCounter = Math.max(prev - 1, 1);

      // Scroll logic for mobile view in helpMode, only if counter was above 4
      if (isXSScreen && helpMode && prev > 4) {
        const remInPixels = parseFloat(
          getComputedStyle(document.documentElement).fontSize
        );
        window.scrollBy({
          top: -30 * remInPixels, // Scroll upwards by 30rem in pixels
          behavior: "smooth",
        });
      }

      return newCounter;
    });
  };

  const handleGPChange = (event) => {
    const {
      target: { value },
    } = event;

    // This will contain a set of unique values, transforming "Historical" or "Prediction" to the base name
    const transformedValue = value.map((v) =>
      v.replace(/ Historical| Prediction/g, "")
    );
    setSelectedDatasets(transformedValue);
    // }
  };

  // Keep the filteredData logic the same as the previous message
  const filteredData =
    // selectedDatasets.includes("All")
    //   ? grossProfit
    //   :
    grossProfit.filter((d) => {
      const datasetNameWithoutSuffix = d.id.replace(
        / Historical| Prediction/g,
        ""
      );
      return selectedDatasets.includes(datasetNameWithoutSuffix);
    });

  const filteredPercentageData =
    // selectedDatasets.includes("All")
    //   ? grossProfitPercent
    //   :
    grossProfitPercent.filter((d) => {
      const datasetNameWithoutSuffix = d.id.replace(
        / Historical| Prediction/g,
        ""
      );
      return selectedDatasets.includes(datasetNameWithoutSuffix);
    });

  const fetchRestaurants = useCallback(async () => {
    // Replace the URL with the appropriate API endpoint for fetching a single task
    // setLoad(true);
    const restro_response = await fetch(
      `${prefix}/api/restaurants/get_restaurants/?company=${company}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    if (restro_response.ok) {
      const restro_data = await restro_response.json();

      const userDefaultRestaurants = JSON.parse(
        localStorage.getItem("user")
      )?.default_restaurants;

      let filteredRestaurants = restro_data.results.filter(
        (restaurant) =>
          userDefaultRestaurants.includes(restaurant.id) &&
          restaurant.name !== "Fisketorget Stavanger"
      );
      if (userDefaultRestaurants && userDefaultRestaurants.length > 0) {
        // Filter restaurants based on default_restaurants IDs
        filteredRestaurants = restro_data.results.filter((restaurant) =>
          userDefaultRestaurants.includes(restaurant.id)
        );
      }
      if (!userDefaultRestaurants || userDefaultRestaurants.length === 0) {
        filteredRestaurants = restro_data.results.filter(
          (restaurant) => restaurant.name !== "Fisketorget Stavanger"
        );
      }
      // Apply sorting to move 'Food Truck' to the bottom of the list
      filteredRestaurants.sort((a, b) => {
        const isAFoodTruck = a.name.includes("Food Truck");
        const isBFoodTruck = b.name.includes("Food Truck");

        if (isAFoodTruck && !isBFoodTruck) {
          return 1; // Place 'a' after 'b'
        } else if (!isAFoodTruck && isBFoodTruck) {
          return -1; // Place 'a' before 'b'
        }
        return 0; // Keep original order for other restaurants
      });
      setDefaultRestaurants(filteredRestaurants);

      // if (primaryRestaurant) {
      //   const mainRestaurant = filteredRestaurants.filter(
      //     (restaurant) => restaurant.id === primaryRestaurant
      //   );
      //   setSelectedRestaurant([mainRestaurant[0]?.name]);
      // }
    }
  }, [
    // primaryRestaurant,
    // selectedRestaurant,
    company,
  ]);

  // const handleRangeChange = (event) => {
  //   const newValue = event.target.value;
  //   if (newValue !== null) {
  //     // This check ensures that a button remains selected even if it's clicked again
  //     setRange(newValue);
  //   }
  // };

  function isBefore(date1, date2) {
    const dateOnly1 = new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const dateOnly2 = new Date(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );
    return dateOnly1 < dateOnly2;
  }

  const handleToggleKPI = () => {
    setIsFlatKPIVisible((prev) => !prev);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(value);
    setSelectedRestaurant(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const adjustDates = (date, dataRange, direction) => {
    let newDate = new Date(date);
    const adjustDay = direction === "forward" ? 1 : -1;
    const adjustWeek = direction === "forward" ? 7 : -7;
    const adjustMonth = direction === "forward" ? 1 : -1;

    switch (dataRange) {
      case "today":
        newDate.setDate(newDate.getDate() + adjustDay);
        break;
      case "thisweek":
        newDate.setDate(newDate.getDate() + adjustWeek);
        break;
      case "thismonth":
        newDate.setMonth(newDate.getMonth() + adjustMonth);
        break;
      default:
        break;
    }

    return newDate.toISOString().split("T")[0];
  };

  const handleBackwardClick = () => {
    // Reset state variables to null or initial state
    // setTotalSalesStat(null);
    // setTotalGrossProfitStat(null);
    // setTotalNetProfitStat(null);
    setAverageEmployeeCost(null);
    setAverageEmployeeProductivity(null);
    setAverageSickCost(null);
    setStatStaffData(null);
    setGrossProfit([]);
    setStaffData([]);

    const newStartDate = adjustDates(startDate, dataRange, "backward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "backward"));
    }
  };

  const handleForwardClick = () => {
    // Reset state variables to null or initial state
    // setTotalSalesStat(null);
    // setTotalGrossProfitStat(null);
    // setTotalNetProfitStat(null);
    setAverageEmployeeCost(null);
    setAverageEmployeeProductivity(null);
    setAverageSickCost(null);
    setStatStaffData(null);
    setGrossProfit([]);
    setStaffData([]);

    const newStartDate = adjustDates(startDate, dataRange, "forward");
    setStartDate(newStartDate);

    if (dataRange === "thismonth") {
      // Set endDate to the last day of the new month
      const monthEndDate = new Date(newStartDate);
      monthEndDate.setMonth(monthEndDate.getMonth() + 1, 0); // Last day of the month
      setEndDate(monthEndDate.toISOString().split("T")[0]);
    } else {
      setEndDate(adjustDates(endDate, dataRange, "forward"));
    }
  };

  //function to format the URLS for fetch
  const buildFetchUrl = (baseURL, options) => {
    let {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      range,
      // dateAdjustment,
    } = options;
    let startingDate = startDate;
    let endingDate = endDate;

    let formattedStartDate = startingDate;
    let formattedEndDate = endingDate;
    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }

    // Constructing URL with query parameters
    let url = baseURL;
    let isFirstQueryParam = true;

    // Helper function to append parameters
    const appendParam = (key, value) => {
      if (isFirstQueryParam) {
        url += `?${key}=${encodeURIComponent(value)}`;
        isFirstQueryParam = false;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // Append company if present
    if (company) {
      appendParam("company", company);
    }

    // Append start_date and end_date if present
    if (formattedStartDate && formattedEndDate) {
      appendParam("start_date", startDate);
      appendParam("end_date", endDate);
    }

    // Append restaurantNames if present
    if (selectedRestaurant.length > 0) {
      appendParam("restaurants", JSON.stringify(selectedRestaurant));
    }

    // Append newValue if present
    if (range) {
      appendParam("period", range);
    }

    // Return the URL as a string
    return url;
  };

  function processDate(date, newValue) {
    let date_period;

    if (newValue === "week") {
      let [year, week] = date.split(" ");
      let actualYear = parseInt(year, 10);
      const firstJan = new Date(Date.UTC(actualYear, 0, 1)); // January 1st of the given year

      // Calculate the first Monday of the year
      const dayOffset = (1 - firstJan.getUTCDay() + 7) % 7;
      const firstMonday = new Date(firstJan);
      firstMonday.setDate(firstJan.getDate() + dayOffset);

      // Calculate the start of the given week
      const weekOffset = (week - 1) * 7;
      date_period = new Date(firstMonday);
      date_period.setUTCDate(firstMonday.getUTCDate() + weekOffset);
    } else if (newValue === "month") {
      const [year, month] = date.split(" ");
      date_period = new Date(
        Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, 23)
      ); // Set to the last day of the given month
    } else if (newValue === "hour") {
      // This assumes the `dayjs` library is available
      const newDate = dayjs(date);
      date_period = newDate.format("YYYY-MM-DD HH:mm");
    } else {
      date_period = new Date(date);
    }

    return date_period;
  }

  //API fetch for employee productivity
  const getEmployeeProductivity = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/employee_info/employee_productivity_per_day`,
      urlOptions
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    setProductivityLoad(true);

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results) {
          const absoluteEmployeeProductivity = data.results.map((entry) => {
            const { period, avg_productivity } = entry;
            return {
              id: period,
              value: parseFloat(avg_productivity.toFixed(0)), // Use the percentage with 2 decimal points as 'value'
            };
          });
          const totalProductivity = absoluteEmployeeProductivity?.reduce(
            (acc, curr) => acc + curr.value,
            0
          );
          const avgProductivity =
            totalProductivity / absoluteEmployeeProductivity.length;

          // Set state for the average
          setAverageEmployeeProductivity(avgProductivity.toFixed(0));
          setProductivityLoad(false);
        } else {
          setAverageEmployeeProductivity([]);
          setProductivityLoad(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching staff data:", error);
        setProductivityLoad(false);
      });
  }, [endDate, selectedRestaurant, startDate, range, dataRange, company]);

  function aggregateAndFormatData(dataSet) {
    // Aggregate each series separately
    const aggregateSeries = (seriesData) => {
      const aggregated = {};
      seriesData.data.forEach((entry) => {
        const { x, y, emp_cost, rent, delivery_cost, fixed_cost, cost } = entry;

        // Assuming 'x' is already a Date object or can be converted into one.
        // If 'x' is not a Date object, you might need to convert it using new Date(x) depending on the original format.
        const dateKey =
          x instanceof Date ? x.toISOString() : new Date(x).toISOString();

        if (!aggregated[dateKey]) {
          aggregated[dateKey] = {
            dateObj: new Date(x), // Store the actual Date object for use in final output
            y: 0,
            emp_cost: 0,
            rent: 0,
            delivery_cost: 0,
            fixed_cost: 0,
            cost: 0,
          };
        }
        aggregated[dateKey].y += y;
        if (emp_cost !== undefined) aggregated[dateKey].emp_cost += emp_cost;
        if (rent !== undefined) aggregated[dateKey].rent += rent;
        if (delivery_cost !== undefined)
          aggregated[dateKey].delivery_cost += delivery_cost;
        if (fixed_cost !== undefined)
          aggregated[dateKey].fixed_cost += fixed_cost;
        if (cost !== undefined) aggregated[dateKey].cost += cost;
      });

      // Convert the object back into an array, using the stored Date object for 'x'
      return Object.values(aggregated).map((item) => ({
        x: item.dateObj,
        y: parseFloat(item.y.toFixed(0)),
        emp_cost: parseInt(item.emp_cost.toFixed(0)),
        rent: parseInt(item.rent.toFixed(0)),
        delivery_cost: parseInt(item.delivery_cost.toFixed(0)),
        fixed_cost: parseInt(item.fixed_cost.toFixed(0)),
        cost: parseInt(item.cost.toFixed(0)),
      }));
    };

    return [
      {
        id: dataSet.operating_profit.id,
        color: dataSet.operating_profit.color, // Retain color
        data: aggregateSeries(dataSet.operating_profit),
      },
      {
        id: dataSet.gross_profit.id,
        color: dataSet.gross_profit.color, // Retain color
        data: aggregateSeries(dataSet.gross_profit),
      },
      {
        id: dataSet.total_net.id,
        color: dataSet.total_net.color, // Retain color
        data: aggregateSeries(dataSet.total_net),
      },
    ];
  }

  function aggregateSeries(seriesData) {
    const aggregated = {};
    seriesData.data.forEach((entry) => {
      const { x, y, emp_cost, rent, delivery_cost, fixed_cost, cost } = entry;
      const dateKey =
        x instanceof Date ? x.toISOString() : new Date(x).toISOString();

      if (!aggregated[dateKey]) {
        aggregated[dateKey] = {
          dateObj: new Date(x),
          y: 0,
          emp_cost: 0,
          rent: 0,
          delivery_cost: 0,
          fixed_cost: 0,
          cost: 0,
        };
      }

      aggregated[dateKey].y += y;
      if (emp_cost !== undefined) aggregated[dateKey].emp_cost += emp_cost;
      if (rent !== undefined) aggregated[dateKey].rent += rent;
      if (delivery_cost !== undefined)
        aggregated[dateKey].delivery_cost += delivery_cost;
      if (fixed_cost !== undefined)
        aggregated[dateKey].fixed_cost += fixed_cost;
      if (cost !== undefined) aggregated[dateKey].cost += cost;
    });

    return Object.values(aggregated).map((item) => ({
      x: item.dateObj,
      y: parseFloat(item.y.toFixed(0)),
      emp_cost: parseInt(item.emp_cost.toFixed(0)),
      rent: parseInt(item.rent.toFixed(0)),
      delivery_cost: parseInt(item.delivery_cost.toFixed(0)),
      fixed_cost: parseInt(item.fixed_cost.toFixed(0)),
      cost: parseInt(item.cost.toFixed(0)),
    }));
  }

  const aggregateAndFormatPercentageData = useCallback((dataSet) => {
    const totalNetData = Object.values(
      aggregateSeries(dataSet.total_net).reduce((acc, item) => {
        acc[item.x.toISOString()] = item;
        return acc;
      }, {})
    );

    const aggregateSeriesPercentage = (seriesData) => {
      const aggregated = {};
      seriesData.data.forEach((entry) => {
        const { x, y, emp_cost, rent, delivery_cost, fixed_cost, cost } = entry;
        const dateKey =
          x instanceof Date ? x.toISOString() : new Date(x).toISOString();

        if (!aggregated[dateKey]) {
          aggregated[dateKey] = {
            dateObj: new Date(x),
            y: 0,
            emp_cost: 0,
            rent: 0,
            delivery_cost: 0,
            fixed_cost: 0,
            cost: 0,
            totalNet:
              totalNetData.find((item) => item.x.toISOString() === dateKey)
                ?.y || 0,
          };
        }

        const totalNet = aggregated[dateKey].totalNet;
        if (totalNet > 0) {
          aggregated[dateKey].y += (y / totalNet) * 100;
          if (emp_cost !== undefined)
            aggregated[dateKey].emp_cost += (emp_cost / totalNet) * 100;
          if (rent !== undefined)
            aggregated[dateKey].rent += (rent / totalNet) * 100;
          if (delivery_cost !== undefined)
            aggregated[dateKey].delivery_cost +=
              (delivery_cost / totalNet) * 100;
          if (fixed_cost !== undefined)
            aggregated[dateKey].fixed_cost += (fixed_cost / totalNet) * 100;
          if (cost !== undefined)
            aggregated[dateKey].cost += (cost / totalNet) * 100;
        } else {
          // If totalNet is zero or negative, set all percentage values to zero.
          aggregated[dateKey].y = 0;
          aggregated[dateKey].emp_cost = 0;
          aggregated[dateKey].rent = 0;
          aggregated[dateKey].delivery_cost = 0;
          aggregated[dateKey].fixed_cost = 0;
          aggregated[dateKey].cost = 0;
        }
      });

      return Object.values(aggregated).map((item) => ({
        x: item.dateObj,
        y: parseFloat(item.y.toFixed(1)),
        emp_cost: parseFloat(item.emp_cost.toFixed(1)),
        rent: parseFloat(item.rent.toFixed(1)),
        delivery_cost: parseFloat(item.delivery_cost.toFixed(1)),
        fixed_cost: parseFloat(item.fixed_cost.toFixed(1)),
        cost: parseFloat(item.cost.toFixed(1)),
      }));
    };

    return [
      {
        id: dataSet.operating_profit.id,
        color: dataSet.operating_profit.color, // Retain color
        data: aggregateSeriesPercentage(dataSet.operating_profit),
      },
      {
        id: dataSet.gross_profit.id,
        color: dataSet.gross_profit.color, // Retain color
        data: aggregateSeriesPercentage(dataSet.gross_profit),
      },
    ];
  }, []);

  const getGrossProfit = useCallback(() => {
    if (
      (!selectedRestaurant || selectedRestaurant.length === 0) &&
      selectedPage === "multiple"
    ) {
      return;
    }

    let url = `${prefix}/api/salesdata/gross_profit`;

    let newValue = range;
    let startingDate = startDate;
    let endingDate = endDate;

    if (dataRange === "today") {
      newValue = "hour";
      if (!startingDate && !endingDate) {
        const now = new Date();
        startingDate = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
        );
        endingDate =
          // hasPredictionData
          //   ? new Date(
          //       Date.UTC(
          //         now.getUTCFullYear(),
          //         now.getUTCMonth(),
          //         now.getUTCDate() + 1
          //       )
          //     )
          //   :
          new Date();
        // Current time in local timezone
      }
    } else if (dataRange === "thisweek") {
      newValue = "daily";
      if (!startingDate && !endingDate) {
        const now = new Date();
        startingDate = new Date(
          Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate() -
              now.getUTCDay() +
              (now.getUTCDay() === 0 ? -6 : 1)
          )
        );
        endingDate = new Date(
          Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate() + (7 - now.getUTCDay())
          )
        );
      }
    } else if (dataRange === "thismonth") {
      if (!startingDate && !endingDate) {
        newValue = "daily";
        const now = new Date();
        startingDate = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)
        );
        endingDate = new Date(
          Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth() + 1, // Move to the next month
            0 // This will get the last day of the current month
          )
        );
      }
    }
    if (newValue === "hour") {
      url = `${prefix}/api/salesdata/hourly_gross_profit`;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    let formattedStartDate;
    let formattedEndDate;
    if (dataRange === "today") {
      formattedStartDate = toISOStringWithoutTimezone(startingDate);
      formattedEndDate = toISOStringWithoutTimezone(endingDate);
    } else if (startDate && endDate) {
      formattedStartDate = formatDate(startingDate);

      formattedEndDate = formatDate(endingDate);
    } else {
      formattedStartDate = startingDate
        ? new Date(startingDate).toISOString().split("T")[0]
        : null;
      formattedEndDate = endingDate
        ? new Date(endingDate).toISOString().split("T")[0]
        : null;
    }

    if (company) {
      url += `?company=${company}`;
    }

    formattedStartDate = startDate;
    formattedEndDate = endDate;

    if (
      (formattedEndDate && !formattedStartDate) ||
      (formattedStartDate && !formattedEndDate)
    ) {
      return;
    }
    if (formattedStartDate > formattedEndDate) {
      setOpen(true);
      setSeverity("error");
      setMessage("Start date cannot be further than end date");
      return;
    }
    if (formattedStartDate && formattedEndDate) {
      url += `&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    }

    if (selectedRestaurant.length > 0) {
      url += `&restaurant=${JSON.stringify(selectedRestaurant)}`;
    }
    if (newValue) {
      url += `&period=${newValue}`;
    }
    abortControllerRefForGrossProfit.current.abort();
    abortControllerRefForGrossProfit.current = new AbortController();

    setProfitLoad(true);

    fetch(url, {
      headers: headers,
      signal: abortControllerRefForGrossProfit.current.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        //chart line data
        const {
          historical,
          //  prediction,
          actual_prediction,
        } = data.results || {};

        // const processDataSet = (dataSet, label) => {
        //   const isPrediction = label === "Prediction";

        //   const getColor = (lineType) => {
        //     return getLineColor(lineType, isPrediction ? 7 : 1);
        //   };

        //   const processedData = {
        //     operating_profit: {
        //       id: `Gross Profit II ${label}`,
        //       color: getColor("Gross Profit II"),
        //       data: [],
        //     },
        //     gross_profit: {
        //       id: `Gross Profit I ${label}`,
        //       color: getColor("Gross Profit I"),
        //       data: [],
        //     },
        //     total_net: {
        //       id: `Sales ${label}`,
        //       color: getColor("Sales"),
        //       data: [],
        //     },
        //   };

        //   if (dataRange === "today") {
        //     return dataSet?.reduce((result, entry) => {
        //       const {
        //         total_net,
        //         gross_profit,
        //         date,
        //         employee_cost,
        //         rent,
        //         delivery_cost,
        //         fixed_cost,
        //         cost,
        //         operating_profit,
        //       } = entry;

        //       let date_period = processDate(date, newValue);

        //       return {
        //         ...result,
        //         operating_profit: {
        //           id: `Gross Profit II ${label}`,
        //           color: getColor("Gross Profit II"),
        //           data: [
        //             ...(result.operating_profit?.data || []),
        //             {
        //               x: date_period,
        //               y: parseFloat(operating_profit?.toFixed(0)),
        //               emp_cost: parseInt(employee_cost?.toFixed(0)),
        //               rent: parseInt(rent?.toFixed(0)),
        //               delivery_cost: parseInt(delivery_cost?.toFixed(0)),
        //               fixed_cost: parseInt(fixed_cost?.toFixed(0)),
        //             },
        //           ],
        //         },
        //         gross_profit: {
        //           id: `Gross Profit I ${label}`,
        //           color: getColor("Gross Profit I"),
        //           data: [
        //             ...(result.gross_profit?.data || []),
        //             {
        //               x: date_period,
        //               y: parseFloat(gross_profit?.toFixed(0)),
        //               cost: parseInt(cost?.toFixed(0)),
        //             },
        //           ],
        //         },
        //         total_net: {
        //           id: `Sales ${label}`,
        //           color: getColor("Sales"),
        //           data: [
        //             ...(result.total_net?.data || []),
        //             {
        //               x: date_period,
        //               y: parseFloat(total_net?.toFixed(0)),
        //             },
        //           ],
        //         },
        //       };
        //     }, {});
        //   } else {
        //     // Helper function to format date based on the period
        //     const formatDateForPeriod = (date, period) => {
        //       if (period === "hour") {
        //         return date.toISOString().substring(0, 13); // YYYY-MM-DDTHH
        //       } else if (period === "daily") {
        //         return date.toISOString().substring(0, 10); // YYYY-MM-DD
        //       }
        //       return date.toISOString().substring(0, 10); // Default to daily format
        //     };

        //     const dateMap = new Map();
        //     const start = new Date(startDate);
        //     const end = new Date(endDate);
        //     let currentDate = new Date(start);

        //     // Initialize dateMap with all expected dates
        //     if (dataRange === "thismonth" || dataRange === "thisweek") {
        //       while (currentDate <= end) {
        //         const formattedDate = formatDateForPeriod(
        //           currentDate,
        //           newValue
        //         );
        //         dateMap.set(formattedDate, {
        //           operating_profit: 0,
        //           gross_profit: 0,
        //           total_net: 0,
        //           emp_cost: 0,
        //           rent: 0,
        //           delivery_cost: 0,
        //           fixed_cost: 0,
        //           cost: 0,
        //         });

        //         if (newValue === "hour") {
        //           currentDate.setHours(currentDate.getHours() + 1);
        //         } else if (newValue === "daily") {
        //           currentDate.setDate(currentDate.getDate() + 1);
        //         } else {
        //           currentDate.setDate(currentDate.getDate() + 1); // Default to daily
        //         }
        //       }
        //     }

        //     // Process the dataset
        //     dataSet?.forEach((entry) => {
        //       const {
        //         total_net,
        //         gross_profit,
        //         date,
        //         employee_cost,
        //         rent,
        //         delivery_cost,
        //         fixed_cost,
        //         cost,
        //         operating_profit,
        //       } = entry;

        //       const date_period = formatDateForPeriod(new Date(date), newValue);

        //       if (dateMap.has(date_period)) {
        //         const dataEntry = dateMap.get(date_period);
        //         dataEntry.operating_profit = parseFloat(
        //           operating_profit?.toFixed(0)
        //         );
        //         dataEntry.gross_profit = parseFloat(gross_profit?.toFixed(0));
        //         dataEntry.total_net = parseFloat(total_net?.toFixed(0));
        //         dataEntry.emp_cost = parseInt(employee_cost?.toFixed(0));
        //         dataEntry.rent = parseInt(rent?.toFixed(0));
        //         dataEntry.delivery_cost = parseInt(delivery_cost?.toFixed(0));
        //         dataEntry.fixed_cost = parseInt(fixed_cost?.toFixed(0));
        //         dataEntry.cost = parseInt(cost?.toFixed(0));
        //       } else {
        //         // If dateMap is not used (for other data ranges), directly push data
        //         processedData.operating_profit.data.push({
        //           x: date_period,
        //           y: parseFloat(operating_profit?.toFixed(0)),
        //           emp_cost: parseInt(employee_cost?.toFixed(0)),
        //           rent: parseInt(rent?.toFixed(0)),
        //           delivery_cost: parseInt(delivery_cost?.toFixed(0)),
        //           fixed_cost: parseInt(fixed_cost?.toFixed(0)),
        //         });
        //         processedData.gross_profit.data.push({
        //           x: date_period,
        //           y: parseFloat(gross_profit?.toFixed(0)),
        //           cost: parseInt(cost?.toFixed(0)),
        //         });
        //         processedData.total_net.data.push({
        //           x: date_period,
        //           y: parseFloat(total_net?.toFixed(0)),
        //         });
        //       }
        //     });

        //     // Populate processedData with entries from dateMap if applicable
        //     if (dataRange === "thismonth" || dataRange === "thisweek") {
        //       dateMap.forEach((value, key) => {
        //         processedData.operating_profit.data.push({
        //           x: key,
        //           y: value.operating_profit,
        //           emp_cost: value.emp_cost,
        //           rent: value.rent,
        //           delivery_cost: value.delivery_cost,
        //           fixed_cost: value.fixed_cost,
        //         });
        //         processedData.gross_profit.data.push({
        //           x: key,
        //           y: value.gross_profit,
        //           cost: value.cost,
        //         });
        //         processedData.total_net.data.push({
        //           x: key,
        //           y: value.total_net,
        //         });
        //       });
        //     }

        //     return processedData;
        //   }
        // };

        const processDataSet = (dataSet, label) => {
          const isPrediction = label === "Prediction";

          const getColor = (lineType) => {
            return getLineColor(lineType, isPrediction ? 7 : 1);
          };

          const processedData = {
            operating_profit: {
              id: `Gross Profit II ${label}`,
              color: getColor("Gross Profit II"),
              data: [],
            },
            gross_profit: {
              id: `Gross Profit I ${label}`,
              color: getColor("Gross Profit I"),
              data: [],
            },
            total_net: {
              id: `Sales ${label}`,
              color: getColor("Sales"),
              data: [],
            },
          };

          if (dataRange === "today") {
            return dataSet?.reduce((result, entry) => {
              const {
                total_net,
                gross_profit,
                date,
                employee_cost,
                rent,
                delivery_cost,
                fixed_cost,
                cost,
                operating_profit,
              } = entry;

              let date_period = processDate(date, newValue);

              return {
                ...result,
                operating_profit: {
                  id: `Gross Profit II ${label}`,
                  color: getColor("Gross Profit II"),
                  data: [
                    ...(result.operating_profit?.data || []),
                    {
                      x: date_period,
                      y: parseFloat(operating_profit?.toFixed(0)),
                      emp_cost: parseInt(employee_cost?.toFixed(0)),
                      rent: parseInt(rent?.toFixed(0)),
                      delivery_cost: parseInt(delivery_cost?.toFixed(0)),
                      fixed_cost: parseInt(fixed_cost?.toFixed(0)),
                    },
                  ],
                },
                gross_profit: {
                  id: `Gross Profit I ${label}`,
                  color: getColor("Gross Profit I"),
                  data: [
                    ...(result.gross_profit?.data || []),
                    {
                      x: date_period,
                      y: parseFloat(gross_profit?.toFixed(0)),
                      cost: parseInt(cost?.toFixed(0)),
                    },
                  ],
                },
                total_net: {
                  id: `Sales ${label}`,
                  color: getColor("Sales"),
                  data: [
                    ...(result.total_net?.data || []),
                    {
                      x: date_period,
                      y: parseFloat(total_net?.toFixed(0)),
                    },
                  ],
                },
              };
            }, {});
          } else {
            // Helper function to format date based on the period
            const formatDateForPeriod = (date, period) => {
              if (period === "hour") {
                return date.toISOString().substring(0, 13); // YYYY-MM-DDTHH
              } else if (period === "daily") {
                return date.toISOString().substring(0, 10); // YYYY-MM-DD
              }
              return date.toISOString().substring(0, 10); // Default to daily format
            };

            const dateMap = new Map();
            const start = new Date(startDate);
            const end = new Date(endDate);
            let currentDate = new Date(start);

            // Initialize dateMap with all expected dates
            if (dataRange === "thismonth" || dataRange === "thisweek") {
              while (currentDate <= end) {
                const formattedDate = formatDateForPeriod(
                  currentDate,
                  newValue
                );
                dateMap.set(formattedDate, {
                  operating_profit: 0,
                  gross_profit: 0,
                  total_net: 0,
                  emp_cost: 0,
                  rent: 0,
                  delivery_cost: 0,
                  fixed_cost: 0,
                  cost: 0,
                });

                if (newValue === "hour") {
                  currentDate.setHours(currentDate.getHours() + 1);
                } else if (newValue === "daily") {
                  currentDate.setDate(currentDate.getDate() + 1);
                } else {
                  currentDate.setDate(currentDate.getDate() + 1); // Default to daily
                }
              }
            }

            // Process the dataset
            dataSet?.forEach((entry) => {
              const {
                total_net,
                gross_profit,
                date,
                employee_cost,
                rent,
                delivery_cost,
                fixed_cost,
                cost,
                operating_profit,
              } = entry;

              const date_period = formatDateForPeriod(new Date(date), newValue);

              if (dateMap.has(date_period)) {
                const dataEntry = dateMap.get(date_period);
                dataEntry.operating_profit += parseFloat(
                  operating_profit?.toFixed(0)
                );
                dataEntry.gross_profit += parseFloat(gross_profit?.toFixed(0));
                dataEntry.total_net += parseFloat(total_net?.toFixed(0));
                dataEntry.emp_cost += parseInt(employee_cost?.toFixed(0));
                dataEntry.rent += parseInt(rent?.toFixed(0));
                dataEntry.delivery_cost += parseInt(delivery_cost?.toFixed(0));
                dataEntry.fixed_cost += parseInt(fixed_cost?.toFixed(0));
                dataEntry.cost += parseInt(cost?.toFixed(0));
              } else {
                // If dateMap is not used (for other data ranges), directly push data
                processedData.operating_profit.data.push({
                  x: date_period,
                  y: parseFloat(operating_profit?.toFixed(0)),
                  emp_cost: parseInt(employee_cost?.toFixed(0)),
                  rent: parseInt(rent?.toFixed(0)),
                  delivery_cost: parseInt(delivery_cost?.toFixed(0)),
                  fixed_cost: parseInt(fixed_cost?.toFixed(0)),
                });
                processedData.gross_profit.data.push({
                  x: date_period,
                  y: parseFloat(gross_profit?.toFixed(0)),
                  cost: parseInt(cost?.toFixed(0)),
                });
                processedData.total_net.data.push({
                  x: date_period,
                  y: parseFloat(total_net?.toFixed(0)),
                });
              }
            });

            // Populate processedData with entries from dateMap if applicable
            if (dataRange === "thismonth" || dataRange === "thisweek") {
              dateMap.forEach((value, key) => {
                processedData.operating_profit.data.push({
                  x: key,
                  y: value.operating_profit,
                  emp_cost: value.emp_cost,
                  rent: value.rent,
                  delivery_cost: value.delivery_cost,
                  fixed_cost: value.fixed_cost,
                });
                processedData.gross_profit.data.push({
                  x: key,
                  y: value.gross_profit,
                  cost: value.cost,
                });
                processedData.total_net.data.push({
                  x: key,
                  y: value.total_net,
                });
              });
            }

            return processedData;
          }
        };

        // const Latest = new Date(latest_created_at);
        // const dateEnd = new Date(endDate);

        console.log(historical, "before processing");

        const historicalData = processDataSet(historical, "Historical");

        let predictionData; // Declare predictionData in the parent scope

        // Condition to check if endDate is before latest_created_at
        // if (isBefore(dateEnd, Latest)) {
        predictionData = processDataSet(actual_prediction, "Prediction");
        // } else {
        //   predictionData = processDataSet(prediction, "Prediction");
        // }

        // const predictionData = processDataSet(prediction, "Prediction");
        console.log(historicalData, "before aggregation");

        if (historicalData && Object.keys(historicalData).length > 0) {
          if (hasPredictionData) {
            setGrossProfit([
              ...aggregateAndFormatData(historicalData),
              ...aggregateAndFormatData(predictionData),
            ]);

            setGrossProfitPercent([
              ...aggregateAndFormatPercentageData(historicalData),
              ...aggregateAndFormatPercentageData(predictionData),
            ]);
          } else {
            setGrossProfit(aggregateAndFormatData(historicalData));
            setGrossProfitPercent(
              aggregateAndFormatPercentageData(historicalData)
            );
          }
        } else {
          setGrossProfit(aggregateAndFormatData(predictionData));
          setGrossProfitPercent(
            aggregateAndFormatPercentageData(predictionData)
          );
        }

        setProfitLoad(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setProfitLoad(false);
        }
      });
  }, [
    endDate,
    range,
    selectedRestaurant,
    startDate,
    dataRange,
    selectedPage,
    aggregateAndFormatPercentageData,
    hasPredictionData,
    company,
    // latest_created_at,
  ]);

  const getDeliveryCost = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
    };
    const Url = buildFetchUrl(
      `${prefix}/api/salesdata_hourly/take_out_cost/`,
      urlOptions
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    setDeliveryCostLoad(true);

    fetch(Url, { headers: headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.results && data.results.length > 0) {
          // Sum up the total_take_out from data.message
          const totalTakeOutSum = data.results.reduce(
            (acc, entry) => acc + parseFloat(entry.take_out_sales),
            0
          );

          // Sum up the total_net from data.message
          const totalNetSum = data.results.reduce(
            (acc, entry) => acc + parseFloat(entry.total_net),
            0
          );

          // Assuming isCurrency is a state or a variable indicating if the currency display is enabled

          // Calculate the percentage of the total takeout from the total net
          const percentage = (totalTakeOutSum / totalNetSum) * 100;

          setTotalActualTakeOutPercentage(percentage.toFixed(1));

          // Directly set the total takeout sum to your state variable if isCurrency is true
          setTotalActualTakeOut(totalTakeOutSum);
        } else {
          // Optionally reset the totalActualTakeout if no data is returned
          setTotalActualTakeOut(0);
          setTotalActualTakeOutPercentage(0);
        }
        setDeliveryCostLoad(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setDeliveryCostLoad(false);
      });
  }, [startDate, endDate, selectedRestaurant, dataRange, company]);

  const getDailyStaffData = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let temp_url = `${prefix}/api/salesdata_hourly/constant_employee_hours`;
    const Url = buildFetchUrl(temp_url, urlOptions);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    // Define helper functions inside getDailyStaffData
    function processData(dataSet, totals) {
      dataSet.forEach((entry) => {
        const { employee_cost, total_net } = entry;
        let empCost = parseFloat(employee_cost) || 0;
        let netSales = parseFloat(total_net) || 0;
        totals.employeeCost += empCost;
        totals.netSales += netSales;
      });
    }

    function calculatePercentage(employeeCost, netSales) {
      return netSales > 0 ? ((employeeCost / netSales) * 100).toFixed(1) : 0;
    }

    setDailyStaffLoad(true);

    fetch(Url, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          // Initialize totals for historical and prediction
          let totals = {
            historical: { employeeCost: 0, netSales: 0 },
            prediction: { employeeCost: 0, netSales: 0 },
          };

          // Process historical and prediction data
          processData(data.message.historical, totals.historical);
          if (hasPredictionData) {
            processData(data.message.prediction, totals.prediction);
          }

          // Calculate percentages
          let historicalPercentage = calculatePercentage(
            totals.historical.employeeCost,
            totals.historical.netSales
          );
          let predictionPercentage = hasPredictionData
            ? calculatePercentage(
                totals.prediction.employeeCost,
                totals.prediction.netSales
              )
            : 0;

          // Set the state arrays with historical and prediction data
          setStatStaffData([historicalPercentage, predictionPercentage]);
          setAverageEmployeeCost([
            totals.historical.employeeCost.toFixed(0),
            hasPredictionData ? totals.prediction.employeeCost.toFixed(0) : 0,
          ]);
        } else {
          setStatStaffData([]);
          setAverageEmployeeCost([]);
        }
        setDailyStaffLoad(false);
      })
      .catch((error) => {
        setDailyStaffLoad(false);
        console.error("Error fetching sales data:", error);
      });
  }, [
    startDate,
    endDate,
    selectedRestaurant,
    range,
    dataRange,
    company,
    hasPredictionData,
  ]);

  const getStaffData = useCallback(() => {
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
      range,
    };
    let temp_url =
      dataRange === "today"
        ? `${prefix}/api/salesdata_hourly/hourly_employee_hours`
        : `${prefix}/api/salesdata_hourly/employee_hours`;
    const Url = buildFetchUrl(temp_url, urlOptions);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    let totalEmployeeCost = { historical: 0, prediction: 0 };
    let totalSickCost = { historical: 0, prediction: 0 };
    let totalSales = { historical: 0, prediction: 0 };

    abortControllerRefForStaffData.current.abort();
    abortControllerRefForStaffData.current = new AbortController();

    setStaffLoad(true);

    const processData = (dataSet, type) => {
      // Step 1: Aggregate the data by date_period
      const aggregatedData = dataSet.reduce((acc, entry) => {
        const {
          employee_cost,
          sick_cost,
          total_net,
          date,
          company, // Retain company here
        } = entry;

        const date_period = new Date(processDate(date, range)); // Ensure date_period is a Date object

        if (!acc[date_period]) {
          acc[date_period] = {
            employee_cost: 0,
            sick_cost: 0,
            total_net: 0,
            company: company, // Explicitly include company here
          };
        }

        acc[date_period].employee_cost += parseFloat(employee_cost) || 0;
        acc[date_period].sick_cost += parseFloat(sick_cost) || 0;
        acc[date_period].total_net += parseFloat(total_net) || 0;

        totalEmployeeCost[type] += parseFloat(employee_cost) || 0;
        totalSickCost[type] += parseFloat(sick_cost) || 0;
        totalSales[type] += parseFloat(total_net) || 0;

        return acc;
      }, {});

      // Step 2: Calculate percent_emp_cost for each aggregated time point
      return Object.entries(aggregatedData).reduce(
        (result, [date_period, values]) => {
          const { employee_cost, total_net, company } = values; // Destructure company here

          const percent_emp_cost =
            total_net > 0 ? ((employee_cost / total_net) * 100).toFixed(1) : 0;

          const newData = {
            x: new Date(date_period), // Ensure x is a Date object
            y: parseFloat(percent_emp_cost),
            type,
          };

          if (!result[company]) {
            // Ensure result object is properly structured
            result[company] = {
              id: company,
              data: [],
            };
          }

          result[company].data.push(newData); // Push new data to the company key

          return result;
        },
        {}
      );
    };

    fetch(Url, {
      headers,
      signal: abortControllerRefForStaffData.current.signal,
    })
      .then((response) => {
        if (!response.ok) throw new Error(`HTTP error ${response.status}`);
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          const historicalData = processData(
            data.message.historical,
            "historical"
          );

          const Latest = new Date(latest_created_at);
          const dateEnd = new Date(endDate);
          let predictionData; // Declare predictionData in the parent scope

          // Condition to check if endDate is before latest_created_at
          if (isBefore(dateEnd, Latest)) {
            predictionData = processData(
              data.message.actual_prediction,
              "Prediction"
            );
          } else {
            predictionData = processData(data.message.prediction, "Prediction");
          }

          // const predictionData = processData(
          //   data.message.prediction,
          //   "prediction"
          // );

          // Combine historical and prediction data based on hasPredictionData
          const combinedStaffData = {};
          if (hasPredictionData) {
            ["historical", "prediction"].forEach((type) => {
              const dataSet =
                type === "historical" ? historicalData : predictionData;
              Object.keys(dataSet).forEach((key) => {
                if (!combinedStaffData[key]) {
                  combinedStaffData[key] = {
                    id: key,
                    historical: [],
                    prediction: [],
                  };
                }
                combinedStaffData[key][type] = dataSet[key].data.map(
                  (item) => ({
                    ...item,
                    type,
                  })
                );
              });
            });
          } else {
            Object.keys(historicalData).forEach((key) => {
              if (!combinedStaffData[key]) {
                combinedStaffData[key] = {
                  id: key,
                  historical: [],
                  prediction: [],
                };
              }
              combinedStaffData[key].historical = historicalData[key].data.map(
                (item) => ({
                  ...item,
                  type: "historical",
                })
              );
            });
          }

          const chartData = Object.keys(combinedStaffData)?.reduce(
            (result, key) => {
              const { historical, prediction } = combinedStaffData[key];
              if (historical.length) {
                result.push({ id: "actual", data: historical });
              }
              if (hasPredictionData && prediction.length) {
                result.push({ id: "prediction", data: prediction });
              }
              return result;
            },
            []
          );

          // Setting the combined data for use in your application
          setStaffData(() => chartData);
          const sickCostPercent = hasPredictionData
            ? [totalSickCost.historical, totalSickCost.prediction].map((v) =>
                ((v * 100) / totalEmployeeCost.historical).toFixed(1)
              )
            : [
                (
                  (totalSickCost.historical * 100) /
                  totalEmployeeCost.historical
                ).toFixed(1),
                0,
              ];

          setSickCostPercent(sickCostPercent);

          const averageSickCost = hasPredictionData
            ? [
                totalSickCost.historical.toFixed(0),
                totalSickCost.prediction.toFixed(0),
              ]
            : [totalSickCost.historical.toFixed(0), 0];

          setAverageSickCost(averageSickCost);

          setStaffLoad(false);
        } else {
          setStaffData([]);
          setStatStaffData([]);
          setSickCostPercent([]);
          setAverageSickCost([]);
          setStaffLoad(false);
        }
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
          setStaffData([]);
          setStatStaffData([]);
          setSickCostPercent([]);
          setAverageSickCost([]);
          setStaffLoad(false);
        }
      });
  }, [
    startDate,
    endDate,
    selectedRestaurant,
    range,
    dataRange,
    company,
    hasPredictionData,
    latest_created_at,
  ]);

  const getKpiGoals = useCallback(() => {
    setLoad(true);
    let url = `${prefix}/api/kpi_goals/monthly_kpi_goals/`;
    const urlOptions = {
      startDate,
      endDate,
      company,
      selectedRestaurant,
      dataRange,
    };
    const Url = buildFetchUrl(url, urlOptions);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    fetch(Url, {
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          // console.log(data.message, "KPI goals");
          let totalSalesGoal = 0;
          let totalGrossProfitGoal = 0;
          let totalNetProfitGoal = 0;

          data.message.forEach((entry) => {
            // console.log(entry.daily_gross_goal, "kpi check");
            let dailyGrossGoal = parseFloat(entry.daily_gross_goal);
            let dailyNetGoal = parseFloat(entry.daily_net_goal);
            let dailySalesGoal = parseFloat(entry.daily_sales_goal);

            if (isNaN(dailyGrossGoal)) dailyGrossGoal = 0;
            if (isNaN(dailyNetGoal)) dailyNetGoal = 0;
            if (isNaN(dailySalesGoal)) dailySalesGoal = 0;

            totalSalesGoal += dailySalesGoal;
            totalGrossProfitGoal += dailyGrossGoal;
            totalNetProfitGoal += dailyNetGoal;
          });

          setTotalSalesStatGoal(totalSalesGoal);
          setTotalGrossProfitStatGoal(totalGrossProfitGoal);
          setTotalNetProfitStatGoal(totalNetProfitGoal);
        } else {
          setTotalSalesStatGoal(null);
          setTotalGrossProfitStatGoal(null);
          setTotalNetProfitStatGoal(null);
        }
        setLoad(false);
      })
      .catch((error) => console.error("Error fetching KPI goals", error));
  }, [dataRange, startDate, endDate, selectedRestaurant, company]);

  useEffect(() => {
    fetchRestaurants();
  }, [fetchRestaurants]);

  useEffect(() => {
    // Ensure selectedRestaurant is an array when the component mounts
    if (!Array.isArray(selectedRestaurant)) {
      setSelectedRestaurant([selectedRestaurant]?.filter(Boolean)); // Wrap in array and filter out falsy values
    }
  }, [selectedRestaurant, setSelectedRestaurant]);

  // useEffect(() => {
  //   if (selectedPage === "company") {
  //     setSelectedRestaurant([]);
  //   }
  // }, [setSelectedRestaurant, selectedPage]);

  useEffect(() => {
    function calculateTotals(dataset, types) {
      const results = {};

      types.forEach((type) => {
        let totalSales = 0;
        let totalGrossProfitI = 0;
        let totalGrossProfitII = 0;

        let costSum = 0;
        let empCostSum = 0;
        let rentSum = 0;
        let deliveryCostSum = 0;
        let fixedCostSum = 0;

        dataset.forEach((data) => {
          if (data.id.toLowerCase().includes(type)) {
            data.data.forEach((entry) => {
              if (
                data.id ===
                "Sales " + type.charAt(0).toUpperCase() + type.slice(1)
              ) {
                totalSales += entry.y || 0;
              } else if (
                data.id ===
                "Gross Profit I " + type.charAt(0).toUpperCase() + type.slice(1)
              ) {
                totalGrossProfitI += entry.y || 0;
                costSum += entry.cost || 0;
              } else if (
                data.id ===
                "Gross Profit II " +
                  type.charAt(0).toUpperCase() +
                  type.slice(1)
              ) {
                totalGrossProfitII += entry.y || 0;
                empCostSum += entry.emp_cost || 0;
                rentSum += entry.rent || 0;
                deliveryCostSum += entry.delivery_cost || 0;
                fixedCostSum += entry.fixed_cost || 0;
              }
            });
          }
        });

        results[type] = {
          totalSales,
          totalGrossProfitI,
          totalGrossProfitII,
          costSum,
          empCostSum,
          rentSum,
          deliveryCostSum,
          fixedCostSum,
        };
      });

      return results;
    }

    const types = ["historical", "prediction"];
    const totals = calculateTotals(grossProfit, types);
    setTotals(totals);

    const grossProfitPercentages = types.map((type) =>
      (
        (totals[type].totalGrossProfitI / totals[type].totalSales) *
        100
      ).toFixed(1)
    );
    const netProfitPercentages = types.map((type) =>
      (
        (totals[type].totalGrossProfitII / totals[type].totalSales) *
        100
      ).toFixed(1)
    );

    setAverageGrossProfitPercentage(grossProfitPercentages);
    setAverageNetProfitPercentage(netProfitPercentages);
  }, [grossProfit]);

  useEffect(() => {
    if (selectedPage === "multiple" && selectedRestaurant?.length === 0) {
      setAverageSickCost(0);
      setAverageEmployeeCost(0);
      setAverageEmployeeProductivity(0);
      setAverageGrossProfitPercentage(0);
      setAverageNetProfitPercentage(0);
      setTotalActualTakeOut(0);
      // setTotalSalesStat(0);
      // setTotalGrossProfitStat(0);
      // setTotalNetProfitStat(0);
      setTotalGrossProfitStatGoal(0);
      setTotalNetProfitStatGoal(0);
      setTotalSalesStatGoal(0);
      setStatStaffData(0);
      setStaffData([]);
      // setProductMixData([]);
      setGrossProfit([]);
      setGrossProfitPercent([]);
      // setConstantStaffData([]);
    } else {
      // Delay the execution of these functions by 500ms
      const timer = setTimeout(() => {
        getKpiGoals();
        getStaffData();
        getGrossProfit();
        getEmployeeProductivity();
        getDailyStaffData();
        getDeliveryCost();
      }, 500); // Delay of 500 milliseconds

      // Clear timeout when component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  }, [
    selectedPage,
    selectedRestaurant,
    getKpiGoals,
    getStaffData,
    getGrossProfit,
    getEmployeeProductivity,
    getDailyStaffData,
    getDeliveryCost,
  ]);

  useEffect(() => {
    return () => {
      // // Abort any ongoing fetches for Article Data
      // if (abortControllerRefForArticleData.current) {
      //   abortControllerRefForArticleData.current.abort();
      // }

      // Abort any ongoing fetches for Gross Profit
      if (abortControllerRefForGrossProfit.current) {
        abortControllerRefForGrossProfit.current.abort();
      }

      // Abort any ongoing fetches for Staff Data
      if (abortControllerRefForStaffData.current) {
        abortControllerRefForStaffData.current.abort();
      }

      // if (abortControllerRefForArticleGroupData.current) {
      //   abortControllerRefForArticleGroupData.current.abort();
      // }
    };
  }, []);

  useEffect(() => {
    if (selectedPage === "company") {
      setSelectedRestaurant([]);
    }
  }, [selectedPage, setSelectedRestaurant]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        // border: "solid 1px red",
        boxSizing: "border-box",
        // transform: "Scale(0.8)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: { lg: 1250, xl: 1600, md: 800 },
          alignItems: "center",
          // border: "solid 1px blue",
          boxSizing: "border-box",
          height: { lg: "100vh", xs: "1" },
          // transform: "Scale(1.2)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            // border: "solid 1px green",
            height: "100%",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: { lg: 1, xs: 1 },
              // border: "solid 1px red",
              justifyContent: { lg: "space-evenly", xl: "space-evenly" },
              height: { lg: "10%", xs: "20%" },
              alignItems: "center",
              mt: { lg: 2, xs: 0 },
            }}
          >
            <>
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 1}
              >
                <Box
                  sx={{
                    ...(counter === 1 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    alignItems: "center",
                    mt: { lg: 0, xs: 1 },
                    gap: { lg: 0, xs: 1 },
                    // border: "solid 1px blue",
                  }}
                >
                  {/* {selectedPage === "multiple" ? ( */}
                  <FormControl sx={{ width: 200, m: 0 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      {selectedPage === "company" ? company : "All Restaurants"}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={
                        selectedPage === "company" ? [] : selectedRestaurant
                      }
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          label={
                            selectedPage === "company"
                              ? company
                              : "All Restaurants"
                          }
                        />
                      }
                      // renderValue={(selected) => selected.join(", ")}
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected
                              .map((name) => getRestaurantNameById(name))
                              .join(", ")
                          : getRestaurantNameById(selected)
                      }
                      MenuProps={MenuProps}
                      disabled={selectedPage === "company"}
                      sx={{
                        "& .MuiSelect-select": {
                          // height: '3rem',
                          padding: "0",
                          textIndent: "0.25em",
                          textAlign: "center",
                        },
                        background: "#F8F8F8",
                        mx: ".5rem",
                        height: "3rem",
                        color: "black",
                        boxShadow: "0px 4px 59px rgba(0, 0, 0, 0.21)",
                      }}
                    >
                      {/* {restaurants.map((restaurant) => (
                        <MenuItem key={restaurant.name} value={restaurant.name}>
                          <Checkbox
                            checked={
                              restaurantNames.indexOf(restaurant.name) > -1
                            }
                          />
                          <ListItemText primary={restaurant.name} />
                        </MenuItem>
                        ))} */}

                      {defaultRestaurants.map((restaurant) => (
                        <MenuItem key={restaurant.id} value={restaurant.name}>
                          <Checkbox
                            checked={selectedRestaurant.includes(
                              restaurant.name
                            )}
                          />
                          <ListItemText primary={restaurant.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <ToggleButtonGroup
                      value={selectedPage}
                      exclusive
                      disabled={!isAdmin}
                      onChange={(event) => handlePageChange(event.target.value)}
                      sx={{
                        "& .MuiToggleButton-root": {
                          // height: "3rem",
                          background: "#F8F8F8",
                          "&.Mui-selected": {
                            background: "#D6D6D6", // Darker shade for the selected button
                          },
                        },
                        mx: ".5rem",
                      }}
                      aria-label="Range"
                    >
                      <ToggleButton value="company" aria-label="company">
                        Company
                      </ToggleButton>
                      <ToggleButton value="multiple" aria-label="multiple">
                        Restaurants
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormControl>
                </Box>
              </Tooltip>
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 2}
              >
                <Box
                  sx={{
                    ...(counter === 2 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    mt: { lg: 3 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      // outline: "solid 1px blue",
                      height: "100%",
                      width: { xs: "80%", lg: "100%" },
                    }}
                  >
                    <ArrowBackIcon
                      onClick={handleBackwardClick}
                      sx={{
                        padding: "8px", // Adds space around the icon, increasing the border size
                        cursor: "pointer", // Ensures the cursor is a pointer // Ensures the cursor is a pointer
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                          borderRadius: "50%", // Makes the background round
                        },
                      }}
                    />

                    <FormControl sx={{ width: { lg: 250, xs: 300 }, m: 0 }}>
                      {/* <InputLabel id="range-select-label">Frequency</InputLabel> */}
                      <ToggleButtonGroup
                        value={dataRange}
                        // The range is the same. Just hour for today.
                        //  Otherwise it's just the startdate and enddate that need to be adjusted accordingly
                        exclusive
                        onChange={(e) => {
                          setGrossProfit([]);
                          // setTotalSalesStat(null);
                          // setTotalGrossProfitStat(null);
                          // setTotalNetProfitStat(null);
                          setAverageEmployeeCost(null);
                          setAverageEmployeeProductivity(null);
                          setAverageSickCost(null);
                          setStatStaffData(null);
                          setStaffData([]);
                          setDataRange(e.target.value);
                          let startingDate;
                          let endingDate;
                          switch (e.target.value) {
                            case "today":
                              startingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  now.getUTCDate()
                                )
                              );
                              endingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  now.getUTCDate()
                                )
                              );
                              break;
                            case "thisweek":
                              // Find the start of the week (Monday)
                              startingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  now.getUTCDate() -
                                    now.getUTCDay() +
                                    (now.getUTCDay() === 0 ? -6 : 1)
                                )
                              );
                              // Find the end of the week (Sunday)
                              endingDate = new Date(startingDate);
                              endingDate.setDate(startingDate.getDate() + 6); // Add 6 days to get to Sunday
                              break;

                            case "thismonth":
                              startingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth(),
                                  1
                                )
                              );
                              endingDate = new Date(
                                Date.UTC(
                                  now.getUTCFullYear(),
                                  now.getUTCMonth() + 1,
                                  0
                                ) // Set to the last day of the current month
                              );
                              break;
                            default:
                              // Handle any other cases or default behavior
                              break;
                          }
                          setStartDate(
                            startingDate?.toISOString().split("T")[0]
                          );
                          setEndDate(endingDate?.toISOString().split("T")[0]);
                        }}
                        sx={{
                          "& .MuiToggleButton-root": {
                            height: "3rem",
                            background: "#F8F8F8",
                            "&.Mui-selected": {
                              background: "#D6D6D6", // Darker shade for the selected button
                            },
                          },
                        }}
                        disabled={staffLoad || profitLoad}
                        aria-label="Range"
                      >
                        <ToggleButton
                          value="today"
                          aria-label="Today"
                          fontFamily="Montserrat"
                        >
                          Day
                        </ToggleButton>
                        <ToggleButton value="thisweek" aria-label="This Week">
                          Week
                        </ToggleButton>
                        <ToggleButton value="thismonth" aria-label="This Month">
                          Month
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>

                    <ArrowForwardIcon
                      onClick={handleForwardClick}
                      sx={{
                        padding: "8px", // Adds space around the icon, increasing the border size
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                          borderRadius: "50%", // Makes the background round
                        },
                      }}
                    />
                  </Box>
                  <FormatDesiredDate
                    startDate={startDate}
                    endDate={endDate}
                    dataRange={dataRange}
                  />
                </Box>
              </Tooltip>
            </>

            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 3}
            >
              <Box
                sx={{
                  ...(counter === 3 && helpMode ? getHighlightedStyle() : {}),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip
                  title={
                    "Show only numbers back in time or include predicted numbers as well"
                  }
                  arrow
                  placement="bottom"
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      }}
                    >
                      To Date
                    </Typography>
                    <Switch
                      checked={hasPredictionData}
                      onChange={handlePredictionData}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                      }}
                    >
                      Projected
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            </Tooltip>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isXSScreen && <LastUpdate />}
            </Box>
            <Box
              sx={{
                width: "150px",
                marginBottom: { xs: "1rem", lg: 0 }, // Adds bottom margin on xs screens
              }}
            >
              <SettingsDialog
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                range={range}
                downloadOptions={downloadOptions}
                dataRange={dataRange}
                setDataRange={setDataRange}
                company={company}
                setCompany={setCompany}
                email={email}
              />
              {/* <Tooltip
                title={"Click here to make custom settings for the page"}
              >
                <SettingsIcon
                  onClick={handleOpenSettingsDialog}
                  sx={{
                    // position: "absolute",
                    // top: { lg: "5%", xs: "8%" },
                    // right: { lg: "2%", xs: "4%" },
                    padding: "8px", // Adds space around the icon, increasing the border size

                    cursor: "pointer", // Ensures the cursor is a pointer
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Light gray background on hover
                      borderRadius: "50%", // Makes the background round
                    },
                    // Rest of your styles if you have any
                  }}
                />
              </Tooltip> */}

              <HelpModeButton
                isXSScreen={isXSScreen}
                handleHelpMode={handleHelpMode}
                helpMode={helpMode}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              mt: { lg: 0, xs: 0 },
              // outline: "solid 1px red",
              justifyContent: { lg: "flex-start" },
              alignItems: "center",
              height: { lg: "40%", xl: "40%", xs: "100vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 4}
              placement={isXSScreen ? "top" : "bottom"} // Conditional placement based on screen size
            >
              <Box
                sx={{
                  display: "flex",
                  background: "#FFF",
                  borderRadius: "1rem",
                  width: { lg: "40%", xs: "90%", xl: "40%" },
                  height: { lg: "100%", xs: "27rem" },
                  flexDirection: "column",
                  boxShadow:
                    counter === 4 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  // outline: "solid 1px red",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    height: "20%",
                    width: "100%",
                    // outline: "solid 1px red",
                    position: "relative",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: "50%",
                      width: "100%",
                      // outline: "solid 1px red",
                      mt: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      // position: "relative",
                    }}
                  >
                    {" "}
                    <TitleStyle
                      content={<Typography>{getTooltipContent()}</Typography>}
                    >
                      Key Metrics
                    </TitleStyle>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      height: "50%",
                      width: "100%",
                      // outline: "solid 1px red",
                      // position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 1,
                        position: "absolute",
                        // top: "50%",
                        ...(isXSScreen
                          ? {}
                          : { transform: "translateY(-50%)" }),
                        right: 0,
                        fontFamily: "'Montserrat', sans-serif",
                        // outline: "solid 1px red",
                      }}
                    >
                      <Typography variant="h7">%</Typography>
                      <Switch
                        checked={isFlatKPIVisible}
                        onChange={handleToggleKPI}
                      />
                      <Typography variant="h7">NOK</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    height: "80%",
                    width: "100%",
                    // outline: "solid 1px red",
                    overflowY: "auto",
                  }}
                >
                  {role === "basic" ? (
                    <BasicOverviewTable
                      // TotalData={totals}
                      profitLoad={profitLoad}
                      isFlatKPIVisible={isFlatKPIVisible}
                      GrossProfitPercentage={averageGrossProfitPercentage}
                      GrossProfitIIPercentage={averageNetProfitPercentage}
                      totalSalesStatGoal={totalSalesStatGoal}
                      totalGrossProfitStatGoal={totalGrossProfitStatGoal}
                      totalNetProfitStatGoal={totalNetProfitStatGoal}
                      statStaffData={statStaffData}
                      averageEmployeeProductivity={averageEmployeeProductivity}
                      averageEmployeeCost={averageEmployeeCost}
                      staffLoad={staffLoad}
                      deliveryCostLoad={deliveryCostLoad}
                      load={load}
                      dailyStaffLoad={dailyStaffLoad}
                      productivityLoad={productivityLoad}
                    />
                  ) : (
                    <OverviewTable
                      TotalData={totals}
                      profitLoad={profitLoad}
                      isFlatKPIVisible={isFlatKPIVisible}
                      GrossProfitPercentage={averageGrossProfitPercentage}
                      GrossProfitIIPercentage={averageNetProfitPercentage}
                      totalSalesStatGoal={totalSalesStatGoal}
                      totalGrossProfitStatGoal={totalGrossProfitStatGoal}
                      totalNetProfitStatGoal={totalNetProfitStatGoal}
                      statStaffData={statStaffData}
                      averageSickCost={averageSickCost}
                      sickCostPercent={sickCostPercent}
                      totalActualTakeOut={totalActualTakeOut}
                      totalActualTakeOutPercentage={
                        totalActualTakeOutPercentage
                      }
                      averageEmployeeProductivity={averageEmployeeProductivity}
                      averageEmployeeCost={averageEmployeeCost}
                      staffLoad={staffLoad}
                      deliveryCostLoad={deliveryCostLoad}
                      load={load}
                      dailyStaffLoad={dailyStaffLoad}
                      productivityLoad={productivityLoad}
                    />
                  )}
                </Box>
              </Box>
            </Tooltip>
            {dataRange === "today" || range === "hour" ? (
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 5}
              >
                <Box
                  sx={{
                    ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),

                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "60%", xl: "60%", xs: "90%" },
                    // gap: 2,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 5 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // fontFamily: "Montserrat",
                      width: "100%",
                      position: "relative",
                      justifyContent: "space-between",
                      // outline: "solid red 1px",
                    }}
                  >
                    <FormControl sx={{ m: 1, width: 100 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Data
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        multiple
                        value={selectedDatasets}
                        onChange={handleGPChange}
                        disabled={profitLoad}
                        size="small"
                        input={<OutlinedInput label="Select Data" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {Array.from(
                          new Set(
                            grossProfit.map((d) =>
                              d.id.replace(/ Historical| Prediction/g, "")
                            )
                          )
                        ).map((datasetName) => (
                          <MenuItem key={datasetName} value={datasetName}>
                            <Checkbox
                              checked={selectedDatasets.includes(datasetName)}
                            />
                            <ListItemText primary={datasetName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TitleStyle tooltipKey="sales_and_profit">
                      Sales and Profit
                    </TitleStyle>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 1,
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      <Typography variant="h7">%</Typography>
                      <Switch
                        checked={isPercentChartVisible}
                        onChange={handleToggleChart}
                      />
                      <Typography variant="h7">NOK</Typography>
                    </Box>
                  </Box>

                  {isPercentChartVisible ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "80%",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {profitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "50%",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <GrossProfitHourlyLineChart
                          data={filteredData}
                          isNotPercentage={true}
                          // range={"hour"}
                          // isNotPercentage={true}
                          isMinimal={true}
                          isXSScreen={isXSScreen}
                          allowNegativeY={true}
                          showZeroLine={true}
                          // isMinimal={true}
                          hasPredictionLine={true}
                          // predictionLineDate={switchDate}
                          singleTooltip={true}
                          isMinNonZero={true}
                          isProfitGraph={true}
                          useKpiFormat={false}
                          Latest={latest_created_at}
                          hasPredictionData={hasPredictionData}
                          endDate={endDate}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        height: "80%",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {profitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "50%",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <GrossProfitHourlyLineChart
                          data={filteredPercentageData}
                          isNotPercentage={false}
                          // range={"hour"}
                          isXSScreen={isXSScreen}
                          allowNegativeY={true}
                          showZeroLine={true}
                          isMinimal={true}
                          singleTooltip={true}
                          hasPredictionLine={true}
                          // predictionLineDate={switchDate}
                          isMinNonZero={true}
                          isProfitGraph={true}
                          useKpiFormat={false}
                          hasPredictionData={hasPredictionData}
                          Latest={latest_created_at}
                          endDate={endDate}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                title={tooltipContent[counter]}
                open={helpMode && counter === 5}
              >
                <Box
                  sx={{
                    ...(counter === 5 && helpMode ? getHighlightedStyle() : {}),
                    display: "flex",
                    flexDirection: "column",
                    width: { lg: "60%", xl: "60%", xs: "90%" },
                    // gap: 2,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "1rem",
                    boxShadow:
                      counter === 5 && helpMode
                        ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                        : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                    background: "#FFF",
                    height: { lg: "100%", xs: "30rem" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      fontFamily: "Montserrat",
                      width: "100%",
                      // outline: " solid 1px red",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 1,
                        // outline: "solid 1px red",
                      }}
                    >
                      {/* <FormControl sx={{ m: 1, width: 100 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Select Data
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          multiple
                          value={selectedDatasets}
                          onChange={handleGPChange}
                          input={<OutlinedInput label="Select Data" />}
                          renderValue={(selected) =>
                            selected.length === 1 && selected[0] === "All"
                              ? "All"
                              : selected.join(", ")
                          }
                        >
                          <MenuItem value="All">
                            <Checkbox
                              checked={selectedDatasets.includes("All")}
                            />
                            <ListItemText primary="All" />
                          </MenuItem>
                          {grossProfit.map((dataset) => (
                            <MenuItem key={dataset.id} value={dataset.id}>
                              <Checkbox
                                checked={selectedDatasets.includes(dataset.id)}
                              />
                              <ListItemText primary={dataset.id} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <FormControl sx={{ m: 1, width: 100 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Select Data
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          multiple
                          value={selectedDatasets}
                          onChange={handleGPChange}
                          disabled={profitLoad}
                          size="small"
                          input={<OutlinedInput label="Select Data" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {Array.from(
                            new Set(
                              grossProfit.map((d) =>
                                d.id.replace(/ Historical| Prediction/g, "")
                              )
                            )
                          ).map((datasetName) => (
                            <MenuItem key={datasetName} value={datasetName}>
                              <Checkbox
                                checked={selectedDatasets.includes(datasetName)}
                              />
                              <ListItemText primary={datasetName} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <TitleStyle tooltipKey="sales_and_profit">
                      Sales and Profit
                    </TitleStyle>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 1,
                        fontFamily: "'Montserrat', sans-serif",
                      }}
                    >
                      <Typography variant="h7" sx={{}}>
                        %
                      </Typography>
                      <Switch
                        checked={isPercentChartVisible}
                        onChange={handleToggleChart}
                      />
                      <Typography variant="h7">NOK</Typography>
                    </Box>
                  </Box>
                  {isPercentChartVisible ? (
                    <Box
                      sx={{
                        display: "flex",
                        height: "80%",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {profitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "50%",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <GrossProfitLineChart
                          data={filteredData}
                          isNotPercentage={true}
                          range={range}
                          isXSScreen={isXSScreen}
                          allowNegativeY={true}
                          showZeroLine={true}
                          isMinimal={true}
                          hasPredictionLine={true}
                          // predictionLineDate={switchDate}
                          singleTooltip={true}
                          isMinNonZero={true}
                          noPrefix={true}
                          hasStaffMarker={true}
                          showCurrency={false}
                          Latest={latest_created_at}
                          hasPredictionData={hasPredictionData}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        height: "80%",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {profitLoad ? (
                        <Box
                          sx={{
                            display: "flex",
                            height: "50%",
                          }}
                        >
                          <ChartLoading />
                        </Box>
                      ) : (
                        <GrossProfitLineChart
                          data={filteredPercentageData}
                          isNotPercentage={false}
                          range={range}
                          isXSScreen={isXSScreen}
                          allowNegativeY={true}
                          showZeroLine={true}
                          isMinimal={true}
                          singleTooltip={true}
                          hasPredictionLine={true}
                          // predictionLineDate={switchDate}
                          isMinNonZero={true}
                          hasStaffMarker={true}
                          showCurrency={false}
                          Latest={latest_created_at}
                          hasPredictionData={hasPredictionData}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Tooltip>
            )}
          </Box>
          {/* First section ends here */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              width: "100%",
              gap: 4,
              // border: "solid 1px red",
              justifyContent: "flex-start",
              alignItems: "center",
              height: { lg: "40%", xs: "120vh" },
            }}
          >
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 6}
            >
              <Box
                sx={{
                  ...(counter === 6 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "35%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 6 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "27rem" },
                  // border: "solid red 1px",
                }}
              >
                <ProductPieChart
                  startDate={startDate}
                  endDate={endDate}
                  dataRange={dataRange}
                  selectedRestaurant={selectedRestaurant}
                  range={range}
                  selectedPage={selectedPage}
                  hasPredictionData={hasPredictionData}
                  isXSScreen={isXSScreen}
                  company={company}
                />
              </Box>
            </Tooltip>
            <Tooltip
              arrow
              title={tooltipContent[counter]}
              open={helpMode && counter === 7}
            >
              <Box
                sx={{
                  ...(counter === 7 && helpMode ? getHighlightedStyle() : {}),

                  display: "flex",
                  flexDirection: "column",
                  width: { lg: "65%", xs: "90%" },
                  // gap: 2,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderRadius: "1rem",
                  boxShadow:
                    counter === 7 && helpMode
                      ? "0 0 8px 4px rgba(255, 165, 0, 0.6)"
                      : "0px 4px 59px rgba(0, 0, 0, 0.21)",
                  background: "#FFF",
                  height: { lg: "100%", xs: "30rem" },
                }}
              >
                <TitleStyle tooltipKey="staff_cost">
                  Are we keeping staff cost low?
                </TitleStyle>

                <Box
                  sx={{
                    display: "flex",
                    height: "80%",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  {staffLoad ? (
                    <Box
                      sx={{
                        display: "flex",
                        mt: "5rem",
                      }}
                    >
                      <ChartLoading />
                    </Box>
                  ) : dataRange === "today" ? (
                    <PointHourlyLineChart
                      data={staffData}
                      isDetailedChart={false}
                      isStaffChart={true}
                      range={range}
                      isXSScreen={isXSScreen}
                      isMinimal={true}
                      singleTooltip={false}
                      isMultiRestaurant={true}
                      isOverlapChart={true}
                      hasPredictionLine={true}
                      useKpiFormat={false}
                      Latest={latest_created_at}
                    />
                  ) : (
                    <LineChart
                      data={staffData}
                      isDetailedChart={false}
                      isStaffChart={true}
                      range={range}
                      isXSScreen={isXSScreen}
                      isMinimal={true}
                      singleTooltip={false}
                      hasPredictionLine={true}
                      isOverlapChart={true}
                      Latest={latest_created_at}
                      endDate={endDate}
                      nullPredictions={true}
                      hasPredictionData={hasPredictionData}
                      // employeeCount={false}
                    />
                  )}
                </Box>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        {/*  */}
        <Dialog
          open={openHelpDialog}
          onClose={() => setOpenHelpDialog(false)}
          aria-labelledby="help-dialog-title"
          aria-describedby="help-dialog-description"
        >
          <DialogTitle id="help-dialog-title">{"Help Information"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="help-dialog-description">
              This help button is designed to provide you with additional
              information and guidance on how to use the features of this
              application. Clicking this button will display useful tips and
              FAQs to help you navigate and make the most of the app's
              functionalities.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHelpDialog(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <SnackbarAlert
          open={open}
          severity={severity}
          message={message}
          onClose={handleClose}
        />
        {helpMode && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              width: { lg: "180px", xs: "280px" },
              position: "fixed", // Make it absolute to position at the bottom
              bottom: 20,
              right: 20,
              padding: "10px",
              // border:"solid 1px red"
            }}
          >
            <NavigationButtons
              isXSScreen={isXSScreen}
              setHelpMode={setHelpMode}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              counter={counter}
              maxSteps={maxSteps}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NewProfitLoss;
